import { IonButton, IonItem, IonLabel, IonList, IonSpinner } from '@ionic/react'
import { PropsWithChildren } from 'react'
import { Customer } from '@eo-storefronts/eo-core'
import { searchCustomers } from '../stores/customers/thunks'
import { useAppDispatch } from '../stores'
import { useSelector } from 'react-redux'
import { selectCustomersSearch } from '../stores/customers/selectors'
import { useTranslation } from 'react-i18next'

interface Props {
  onCreate(query: string): void,
  onSelect(customer: Customer): void,
}

export const SearchCustomerResults: React.FC<PropsWithChildren<Props>> = ({ children, onCreate, onSelect }) => {
  const dispatch = useAppDispatch()
  const { loading, query, results } = useSelector(selectCustomersSearch)
  const { t } = useTranslation()

  if (!query) {
    return (
      <div>{children}</div>
    )
  }

  return (
    <IonList className="eo-gray-list" style={{ '--eo-list-padding': '10px' }}>
      <IonItem lines="none" onClick={() => {onCreate(query); dispatch(searchCustomers(''))}}>
        <IonLabel>{query}</IonLabel>
        <IonButton mode="md" slot="end">{t('checkout.createNew')}</IonButton>
      </IonItem>
      {loading && <IonItem lines="none">
        <IonLabel><IonSpinner/> {t('checkout.loadingResults')}</IonLabel>
      </IonItem>}
      {!loading && !results.length && <IonItem lines="none">
        <IonLabel>{t('checkout.noSearchResults')}</IonLabel>
      </IonItem>}
      {results.map(customer => <IonItem lines="none" onClick={() => {onSelect(customer); dispatch(searchCustomers(''))}} key={customer.id}>
        <IonLabel>{customer.first_name} {customer.last_name}<br />{customer.phone ?? t('checkout.noPhone')} &mdash; {customer.email ?? t('checkout.noEmail')}</IonLabel>
        <IonButton mode="md" slot="end">{t('checkout.select')}</IonButton>
      </IonItem>)}
    </IonList>
  )
}
