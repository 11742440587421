import { createAsyncThunk } from '@reduxjs/toolkit'
import { DeliveryMethodNames, ManagerService } from '@eo-storefronts/eo-core'
import { Preferences } from '@capacitor/preferences'

export const loadFirm = createAsyncThunk(
  'firm/loadFirm',
  async () => {
    const firm = await ManagerService.getFirm()
    const { value: deliveryMethodsPreference } = await Preferences.get({ key: `${firm.id}_deliveryMethods` })
    const deliveryMethods = deliveryMethodsPreference
      ? (JSON.parse(deliveryMethodsPreference) as DeliveryMethodNames[])
      : [ DeliveryMethodNames.PICKUP, DeliveryMethodNames.DELIVERY, DeliveryMethodNames.ON_THE_SPOT ]
    const { value: displayImagesPreference } = await Preferences.get({ key: `${firm.id}_displayImages` })
    const displayImages = JSON.parse(displayImagesPreference ?? 'null') ?? { product: true, modifier: true }

    return { firm, deliveryMethods, displayImages }
  }
)
