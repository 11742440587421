"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var uuid_1 = require("uuid");
var UuidService = /** @class */ (function () {
    function UuidService() {
    }
    UuidService.generate = function () {
        return (0, uuid_1.v4)();
    };
    return UuidService;
}());
exports.default = UuidService;
