import { MouseEvent } from 'react'
import { IonButton } from '@ionic/react'

interface Props {
  max?: number|null,
  min?: number,
  value: number,
  onChange(value: number): void,
}

const Stepper: React.FC<Props> = ({ max, min, value, onChange }: Props) => {
  const update = (event: MouseEvent, value: number) => {
    event.stopPropagation()
    onChange(value)
  }

  return (
    <span className="eo-stepper">
      <IonButton mode="md" onClick={event => update(event, value - 1)} disabled={value <= (min || 0)}>-</IonButton>
      <span>{value}</span>
      <IonButton mode="md" onClick={event => update(event, value + 1)} disabled={typeof max === 'number' && value >= max}>+</IonButton>
    </span>
  )
}

export default Stepper
