import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiConfiguration, DeliveryMethodNames, Firm } from '@eo-storefronts/eo-core'
import { Preferences } from '@capacitor/preferences'
import { loadFirm } from './thunks'

export const firmSlice = createSlice({
  name: 'firm',
  initialState: {
    deliveryMethods: [] as DeliveryMethodNames[],
    displayImages: { product: true, modifier: true },
    firm: null as Firm|null
  },
  reducers: {
    setDisplayImages(state, action: PayloadAction<{ product: boolean, modifier: boolean }>) {
      state.displayImages = action.payload
      if (state.firm) {
        Preferences.set({ key: `${state.firm.id}_displayImages`, value: JSON.stringify(action.payload) })
      }
    },
    setMethodEnabled(state, action: PayloadAction<{deliveryMethod: DeliveryMethodNames, enabled: boolean}>) {
      const methods = state.deliveryMethods
        .filter(key => key !== action.payload.deliveryMethod)
        .concat(action.payload.enabled ? [ action.payload.deliveryMethod ] : [])

      state.deliveryMethods = methods

      if (state.firm) {
        Preferences.set({ key: `${state.firm.id}_deliveryMethods`, value: JSON.stringify(methods) })
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadFirm.fulfilled, (state, action) => {
      state.deliveryMethods = action.payload.deliveryMethods
      state.displayImages = action.payload.displayImages
      state.firm = action.payload.firm
      ApiConfiguration.addOrReplaceHeader('firmId', state.firm.id)
    })
  }
})

export const { setDisplayImages, setMethodEnabled } = firmSlice.actions

export default firmSlice.reducer
