import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonRow,
  IonSelect, IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCountries } from '../stores/firm/selectors'
import { Address, Country, ManagerService } from '@eo-storefronts/eo-core'
import { selectCustomer } from '../stores/cart/selectors'
import { useAppDispatch } from '../stores'
import { getAddresses } from '../stores/customers/thunks'
import { selectAddresses } from '../stores/customers/selectors'
import useAutocomplete from '../hooks/useAutocomplete'
import { FormErrors } from '../types/forms'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean,
  onClose(): void,
  onSelect(address: Address): void,
}

type FormFields = 'street' | 'house_number' | 'zip_code' | 'locality' | 'country_id' | 'description'

const SelectAddressModal: React.FC<Props> = ({ isOpen, onClose, onSelect }: Props) => {
  const dispatch = useAppDispatch()
  const countries = useSelector(selectCountries)
  const customer = useSelector(selectCustomer)
  const { t } = useTranslation()
  const { results, loading } = useSelector(selectAddresses)
  const [ errors, setErrors ] = useState<FormErrors<FormFields> | null>(null)
  const countryInput = useRef<HTMLIonSelectElement>(null)
  const form = useRef<HTMLFormElement>(null)
  const { ref: streetInput, setup: setupAutocomplete } = useAutocomplete()

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()
    const form = event.target as HTMLFormElement
    const address: Address = {
      country: countries.find(country => country.id.toString() === form.country_id.value) as Country,
      country_id: Number(form.country_id.value),
      description: form.description.value,
      house_number: form.house_number.value,
      id: 0,
      is_default: false,
      locality: form.locality.value,
      street: form.street.value,
      zip_code: form.zip_code.value
    }

    if (customer) {
      try {
        onSelect(await ManagerService.createAddress(customer, address))
      } catch (e: any) {
        setErrors((e as any).errors ?? {})
      }
      return
    }

    onSelect(address)
  }

  const onPresent = async () => {
    setupAutocomplete((input, components) => {
      const currentCountryInput = countryInput.current as HTMLIonSelectElement
      const currentForm = form.current as HTMLFormElement
      currentForm.street.value = components.route?.long_name ?? ''
      currentForm.house_number.value = components.street_number?.long_name ?? ''
      currentForm.zip_code.value = components.postal_code?.long_name ?? ''
      currentForm.locality.value = components.locality?.long_name ?? ''
      currentCountryInput.value = countries.find(country => country.iso === components.country?.short_name)?.id ?? ''
    })
  }

  useEffect(() => {
    if (isOpen && customer) {
      setErrors(null)
      dispatch(getAddresses(customer.id))
    }
  }, [ isOpen, customer?.id ])

  return (
    <IonModal isOpen={isOpen} onDidPresent={onPresent} onDidDismiss={onClose} className="select-address-modal" style={{ '--width': '900px' }}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('checkout.selectAddress')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div style={{ display: 'flex', height: '100%' }}>
          <IonList className="eo-gray-list" style={{ flex: '1 1 0px' }}>
            {loading && <IonItem lines="none">
              <IonLabel><IonSpinner/> {t('checkout.loadingResults')}</IonLabel>
            </IonItem>}
            {customer && results.map(address => <IonItem key={address.id} lines="none" onClick={() => onSelect(address)}>
              <IonLabel>{`${address.street} ${address.house_number} — ${address.zip_code} ${address.locality}`}</IonLabel>
            </IonItem>)}
          </IonList>
          <form ref={form} className="eo-form" style={{ flex: '1 1 0px' }} onSubmit={onSubmit}>
            {errors && <div className="ion-padding"><IonText color="danger">{t('formErrors')}</IonText></div>}
            <IonGrid>
              <IonRow>
                <IonCol size="9">
                  <IonItem lines="none" className={errors?.street ? 'ion-invalid' : undefined}>
                    <IonLabel position="stacked">{t('address.street')}</IonLabel>
                    <IonInput ref={streetInput} name="street" required />
                    <IonNote slot="error">{errors?.street?.join()}</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="3">
                  <IonItem lines="none" className={errors?.house_number ? 'ion-invalid' : undefined}>
                    <IonLabel position="stacked">{t('address.number')}</IonLabel>
                    <IonInput name="house_number" required />
                    <IonNote slot="error">{errors?.house_number?.join()}</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="3">
                  <IonItem lines="none" className={errors?.zip_code ? 'ion-invalid' : undefined}>
                    <IonLabel position="stacked">{t('address.zip')}</IonLabel>
                    <IonInput name="zip_code" required />
                    <IonNote slot="error">{errors?.zip_code?.join()}</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="9">
                  <IonItem lines="none" className={errors?.locality ? 'ion-invalid' : undefined}>
                    <IonLabel position="stacked">{t('address.city')}</IonLabel>
                    <IonInput name="locality" required />
                    <IonNote slot="error">{errors?.locality?.join()}</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="12">
                  <IonItem lines="none" className={errors?.country_id ? 'ion-invalid' : undefined}>
                    <IonLabel position="stacked">{t('address.country')}</IonLabel>
                    <IonSelect ref={countryInput} name="country_id" interface="popover">
                      {countries.map(country => <IonSelectOption key={country.id} value={country.id}>{country.name}</IonSelectOption>)}
                    </IonSelect>
                    <IonNote slot="error">{errors?.country_id?.join()}</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="12">
                  <IonItem lines="none" className={errors?.description ? 'ion-invalid' : undefined}>
                    <IonLabel position="stacked">{t('address.remarks')}</IonLabel>
                    <IonTextarea name="description" rows={2} />
                    <IonNote slot="error">{errors?.description?.join()}</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="12">
                  <IonButton expand="block" mode="md" type="submit">{customer ? t('checkout.createAddress') : t('checkout.useAddress')}</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </div>
      </IonContent>
    </IonModal>
  )
}

export default SelectAddressModal
