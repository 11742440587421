import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonTextarea,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectOrder } from '../stores/cart/selectors'
import { setComment } from '../stores/cart/slices'
import { useAppDispatch } from '../stores'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean,
  onClose(): void,
}

const RemarksModal: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const input = useRef<HTMLIonTextareaElement>(null)
  const [ order ] = useSelector(selectOrder)
  const [ state, setState ] = useState('')
  const { t } = useTranslation()

  const onPresent = () => {
    setState(order.comment || '')
  }

  const onSave = () => {
    dispatch(setComment(state))
    onClose()
  }

  return (
    <IonModal isOpen={isOpen} onIonModalWillPresent={onPresent} onIonModalDidPresent={async () => (input.current as HTMLIonTextareaElement).setFocus()} onDidDismiss={onClose} className="remarks-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('checkout.remarks')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding eo-form">
        <IonTextarea className="full-height" ref={input} value={state} onIonInput={(ev) => setState(ev.target.value as string)} />
      </IonContent>
      <IonFooter className="ion-padding">
        <IonButton expand="block" mode="md" onClick={onSave}>{t('modal.save')}</IonButton>
      </IonFooter>
    </IonModal>
  )
}

export default RemarksModal
