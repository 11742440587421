import { IonCard, IonCardContent, IonCardTitle, IonRippleEffect } from '@ionic/react'
import EoImg from './EoImg'
import Stepper from './Stepper'
import { Modifier, ModifierGroup } from '@eo-storefronts/eo-core'
import { useLanguage } from '../hooks/useLanguage'
import { useSelector } from 'react-redux'
import { selectCurrency, selectDisplayImages } from '../stores/firm/selectors'

type Props = {
  canAdd: boolean,
  group: ModifierGroup,
  modifier: Modifier,
  onChange(value: number): void,
  quantity: number,
};

export const ProductModifierCard: React.FC<Props> = ({ canAdd, group, modifier, onChange, quantity }) => {
  const language = useLanguage()
  const currency = useSelector(selectCurrency)
  const displayImages = useSelector(selectDisplayImages)

  const showStepper = (group.multipleChoiceMultiselectMax || 1) !== 1 && quantity > 0

  const toggleModifier = () => {
    // clicking the modifier does nothing when the stepper is shown
    if (showStepper) {
      return
    }

    // prevent adding a modifier if max modifiers is already reached
    if (!canAdd && !quantity) {
      return
    }

    onChange(quantity ? 0 : 1)
  }

  return (
    <IonCard className="ion-activatable product-card" disabled={!modifier.inStock} color={quantity ? 'primary' : undefined} onClick={toggleModifier}>
      {showStepper && (
        <div style={{ position: 'absolute', top: '40px', width: '100%', textAlign: 'center', zIndex: '1' }}>
          <Stepper max={canAdd ? group.multipleChoiceMultiselectMax : quantity} value={quantity} onChange={onChange} />
        </div>
      )}

      <IonCardContent>
        {displayImages.modifier && <EoImg alt={modifier.name[language]} src={modifier.images?.[0]?.thumb} />}
        <IonCardTitle>{modifier.name[language]}</IonCardTitle>
        <div className="product-card-price">
          {modifier.price > 0 ? <span>{currency?.symbol} {modifier.price.toFixed(2)}</span> : <span>&nbsp;</span>}
        </div>
      </IonCardContent>

      <IonRippleEffect />
    </IonCard>
  )
}
