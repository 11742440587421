import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader, IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonModal, IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { ticketOutline } from 'ionicons/icons'
import { useState } from 'react'
import { Coupon, Id } from '@eo-storefronts/eo-core'
import { useSelector } from 'react-redux'
import { selectOrder } from '../stores/cart/selectors'
import { setCoupon } from '../stores/cart/slices'
import { selectCurrency } from '../stores/firm/selectors'
import { useAppDispatch } from '../stores'
import useCheckCoupon from '../hooks/useCheckCoupon'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean,
  onClose(): void,
}

type CouponWithProductId = Coupon & { productId?: Id };

const DiscountModal: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const checkCoupon = useCheckCoupon()
  const [ order ] = useSelector(selectOrder)
  const currency = useSelector(selectCurrency)
  const [ state, setState ] = useState({
    code: '',
    error: null as string|null,
    coupon: null as CouponWithProductId | null
  })
  const { t } = useTranslation()

  const setCode = async (code: string) => {
    setState({ code, error: null, coupon: null })
    try {
      const coupon = await checkCoupon(code)

      setState({ code, error: null, coupon })
    } catch (error: any) {
      setState({ code, error: error.message || error, coupon: null })
    }
  }

  const onOpen = () => {
    if (order.coupon_code) {
      setCode(order.coupon_code)
    } else {
      clearCode()
    }
  }

  const clearCode = () => {
    setState({ code: '', error: null, coupon: null })
  }

  const onSave = () => {
    dispatch(setCoupon(state.coupon ? { ...state.coupon, code: state.code } : null))
    onClose()
  }

  return (
    <IonModal isOpen={isOpen} onWillPresent={onOpen} onDidDismiss={onClose} className="discount-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('checkout.applyDiscount')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form onSubmit={event => event.preventDefault()}>
          <IonList>
            <IonItem lines="none">
              <IonIcon slot="start" icon={ticketOutline} size="large" />
              <IonInput placeholder={t('checkout.enterCode') as string} value={state.code} debounce={1000} onIonChange={async (ev) => ev.target.value && setCode(ev.target.value as string)}></IonInput>
              {state && <IonButton slot="end" color="danger" onClick={clearCode}>
                {t('checkout.clearCode')}
              </IonButton>}
            </IonItem>
          </IonList>
          {state.error && <p style={{ textAlign: 'right' }}><IonText color="danger">{state.error}</IonText></p>}
          {state.coupon && <p style={{ textAlign: 'right' }}><IonText color="success">{t('checkout.validCode')} <strong>{t('checkout.validCodeDiscount', { currency: currency?.symbol, value: state.coupon.value.toFixed(2) })}</strong></IonText></p>}
        </form>
      </IonContent>
      <IonFooter className="ion-padding">
        <IonButton expand="block" mode="md" onClick={onSave} disabled={state.code !== '' && !state.coupon}>{t('modal.save')}</IonButton>
      </IonFooter>
    </IonModal>
  )
}

export default DiscountModal
