import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast
} from '@ionic/react'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getInfo } from '../hooks/useWorldline'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../stores'
import { resetDeliveryMethod } from '../stores/cart/thunks'
import { setDisplayImages, setMethodEnabled } from '../stores/firm/slices'
import { selectDeliveryMethods, selectDisplayImages } from '../stores/firm/selectors'
import { selectTerminals } from '../stores/terminals/selectors'
import { cardOutline } from 'ionicons/icons'
import { Worldline } from '@easyorderapp/capacitor-plugin-worldline'

const Settings: React.FC = () => {
  const dispatch = useAppDispatch()
  const [ state, setState ] = useState({
    addresses: [] as string[],
    version: null as string|null
  })
  useEffect(() => {
    getInfo().then(({ addresses, version }) => setState({ addresses, version }))
  }, [])
  const [ present ] = useIonToast()
  const deliveryMethods = useSelector(selectDeliveryMethods)
  const displayImages = useSelector(selectDisplayImages)
  const terminals = useSelector(selectTerminals)
  const { t } = useTranslation()

  const toggleDeliveryMethod = async (key: DeliveryMethodNames, enabled: boolean) => {
    await dispatch(setMethodEnabled({ deliveryMethod: key, enabled }))
    await dispatch(resetDeliveryMethod())
  }

  const testTerminal = async (terminalId: string) => {
    try {
      const result = await Worldline.saleTransaction({ terminalId, amount: 0.01 })
      present({ message: `Sale successful: ${JSON.stringify(result)}`, duration: 5000 })
    } catch (error) {
      present({ message: `Sale failed: ${JSON.stringify((error as any).data)}`, duration: 5000 })
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t('settings.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{t('settings.title')}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{t('settings.deliveryMethods')}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              {deliveryMethods.map(deliveryMethod => (
                <IonItem key={deliveryMethod.key}>
                  <IonCheckbox slot="start" checked={!deliveryMethod.disabled} onIonChange={async ev => toggleDeliveryMethod(deliveryMethod.key, ev.detail.checked)}></IonCheckbox>
                  <IonLabel>{t(`deliveryMethods.${deliveryMethod.key}`)}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{t('settings.otherSettings')}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonCheckbox slot="start" checked={displayImages.product} onIonChange={async ev => dispatch(setDisplayImages({ ...displayImages, product: ev.detail.checked }))}></IonCheckbox>
                <IonLabel>{t('settings.displayProductImages')}</IonLabel>
              </IonItem>
              <IonItem>
                <IonCheckbox slot="start" checked={displayImages.modifier} onIonChange={async ev => dispatch(setDisplayImages({ ...displayImages, modifier: ev.detail.checked }))}></IonCheckbox>
                <IonLabel>{t('settings.displayModifierImages')}</IonLabel>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        {state.version && (
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{t('settings.worldlinePlugin')}</IonCardTitle>
              <IonCardSubtitle>{t('settings.version', { version: state.version })}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              {t('settings.availableAddresses', { addresses: state.addresses.join(' — ') })}
            </IonCardContent>
          </IonCard>
        )}

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{t('settings.connectedTerminals')}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            {terminals.length ? (
              <IonList>
                {terminals.map(terminal => (
                  <IonItem lines="none">
                    <IonIcon icon={cardOutline} slot="start"></IonIcon>
                    <IonLabel>{terminal.name} ({ terminal.id })</IonLabel>
                    <IonButton slot="end" onClick={async () => testTerminal(terminal.id)}>Test</IonButton>
                  </IonItem>
                ))}
              </IonList>
            ) : <p>{t('settings.noConnectedTerminals')}</p>}
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

export default Settings
