import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader, IonItem, IonLabel, IonList,
  IonModal, IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useSelector } from 'react-redux'
import { selectFirm } from '../stores/firm/selectors'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean,
  onClose(): void,
  onSelect(table: string): void,
  value: string|undefined,
}

const SelectTableModal: React.FC<Props> = ({ isOpen, onClose, onSelect, value }: Props) => {
  const firm = useSelector(selectFirm)
  const { t } = useTranslation()

  const tableNumbers = (firm as any).table_numbers as string[]|undefined || []

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} className="table-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('checkout.selectTable')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonRadioGroup value={value} onIonChange={event => onSelect(event.detail.value)}>
            {tableNumbers.map((number, index) => <IonItem lines="full" key={index}>
              <IonLabel>{number}</IonLabel>
              <IonRadio slot="end" value={number}></IonRadio>
            </IonItem>)}
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </IonModal>
  )
}

export default SelectTableModal
