"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ObjectRemoveUndefined = /** @class */ (function () {
    function ObjectRemoveUndefined() {
    }
    ObjectRemoveUndefined.remove = function (obj) {
        var newObj = {};
        Object.keys(obj).forEach(function (key) {
            if (typeof obj[key] === 'object' &&
                obj[key] !== null &&
                !Array.isArray(obj[key])) {
                newObj[key] = ObjectRemoveUndefined.remove(obj[key]);
            }
            else if (obj[key] !== undefined) {
                newObj[key] = obj[key];
            }
        });
        return newObj;
    };
    return ObjectRemoveUndefined;
}());
exports.default = ObjectRemoveUndefined;
