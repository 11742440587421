import { createSlice } from '@reduxjs/toolkit'
import { KnownEntitiesInterface } from '@eo-storefronts/eo-core'
import { loadProducts } from './thunks'

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    data: null as KnownEntitiesInterface|null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadProducts.pending, (state) => {
      state.data = null
      state.loading = true
    })
    builder.addCase(loadProducts.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
    })
  }
})

export default productsSlice.reducer
