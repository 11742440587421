import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Terminal {
  id: string,
  name: string,
  type: string,
}

const terminalsSlice = createSlice({
  name: 'terminals',
  initialState: {
    terminals: [] as Terminal[]
  },
  reducers: {
    addTerminal: (state, action: PayloadAction<Terminal>) => {
      state.terminals.push(action.payload)

      return state
    },
    removeTerminal: (state, action: PayloadAction<string>) => {
      state.terminals = state.terminals.filter(terminal => terminal.id !== action.payload)

      return state
    }
  }
})

export const { addTerminal, removeTerminal } = terminalsSlice.actions

export default terminalsSlice.reducer
