import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonAlert
} from '@ionic/react'
import {
  cardOutline,
  carOutline,
  createOutline,
  locationOutline,
  personCircleOutline,
  radioButtonOff,
  radioButtonOn,
  restaurantOutline,
  star,
  ticketOutline,
  timeOutline
} from 'ionicons/icons'
import { DeliveryMethodNames, PaymentMethodMethodEnum } from '@eo-storefronts/eo-core'
import { clearCart, setCheckoutOption, setPaymentMethod } from '../stores/cart/slices'
import Cart from './Cart'
import { useSelector } from 'react-redux'
import {
  selectCart,
  selectCartErrors,
  selectCustomer,
  selectOrder,
  selectOrderAddress,
  selectPaymentMethodId,
  selectPaymentMethods
} from '../stores/cart/selectors'
import { createOrder } from '../stores/cart/thunks'
import { useAppDispatch } from '../stores'
import { selectCurrency, selectFirm } from '../stores/firm/selectors'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean,
  onClick(modal: string|null): void,
  onClose(): void,
}

const CheckoutModal: React.FC<Props> = ({ isOpen, onClick, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const [ presentAlert ] = useIonAlert()
  const [ order, orderDate, orderLoading ] = useSelector(selectOrder)
  const { t } = useTranslation()
  const { cartDiscount, cartTotal, cartTotalDiscounted } = useSelector(selectCart)
  const cartErrors = useSelector(selectCartErrors)
  const currency = useSelector(selectCurrency)
  const customer = useSelector(selectCustomer)
  const firm = useSelector(selectFirm)
  const orderAddress = useSelector(selectOrderAddress)
  const paymentMethods = useSelector(selectPaymentMethods).filter(method => method.payment_method_id === 1 || method.method === PaymentMethodMethodEnum.CUSTOM)
  const paymentMethodId = useSelector(selectPaymentMethodId)

  const submitOrder = async () => {
    const result = await dispatch(createOrder())

    if (!createOrder.fulfilled.match(result)) {
      presentAlert({
        header: result.error.name,
        message: result.error.message,
        buttons: [ t('alert.dismiss') ]
      })
      return
    }

    presentAlert({
      header: t('checkout.orderCreated'),
      message: t('checkout.orderCreatedMessage', { reference: result.payload.reference }),
      buttons: [ t('alert.dismiss') ]
    })

    dispatch(clearCart())
    onClick(null)
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} className="checkout-modal" style={{ '--width': '900px' }}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('checkout.checkout')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {firm && <IonContent style={{ '--padding-end': '350px' }}>
        <IonList className="checkout-modal-menu" style={{ flexGrow: '1', borderRight: '1px solid var(--ion-color-light)', overflowY: 'scroll' }}>
          <IonItem lines="full" onClick={() => onClick('change-delivery')}>
            <IonIcon slot="start" icon={carOutline} />
            <IonLabel><strong>{t(`deliveryMethods.${order.delivery_method}`)}</strong></IonLabel>
            <IonButton slot="end" size="large" color="edit">{t('checkout.edit')}</IonButton>
          </IonItem>
          {order.delivery_method === DeliveryMethodNames.ON_THE_SPOT && <IonItem lines="full" onClick={() => onClick('table')}>
            <IonIcon slot="start" icon={restaurantOutline} />
            <IonLabel>
              <div><strong>{t('checkout.table')}</strong></div>
              <div>{order.table_number || t('checkout.selectTable')}</div>
            </IonLabel>
            <IonButton slot="end" size="large" color="edit">{t('checkout.edit')}</IonButton>
          </IonItem>}
          <IonItem lines="full" onClick={() => onClick('search')}>
            <IonIcon slot="start" icon={personCircleOutline} />
            <IonLabel>
              <div><strong>{t('checkout.customer')}</strong></div>
              {customer ? <div>
                <div>{customer.first_name} {customer.last_name}</div>
                <div>{customer.phone ?? t('checkout.noPhone')} &mdash; {customer.email ?? t('checkout.noEmail')}</div>
              </div> : t('checkout.selectCustomer')}
            </IonLabel>
            <IonButton slot="end" size="large" color="edit">{t('checkout.edit')}</IonButton>
          </IonItem>
          {order.delivery_method === DeliveryMethodNames.DELIVERY && <IonItem lines="full" onClick={() => onClick('address')}>
            <IonIcon slot="start" icon={locationOutline} />
            <IonLabel>
              <div><strong>{t('checkout.address')}</strong></div>
              <div>{orderAddress || t('checkout.selectAddress')}</div>
            </IonLabel>
            <IonButton slot="end" size="large" color="edit">{t('checkout.edit')}</IonButton>
          </IonItem>}
          <IonItem lines="full" onClick={() => onClick('time-date')}>
            <IonIcon slot="start" icon={timeOutline} />
            <IonLabel>
              <div><strong>{t('checkout.timeAndDate')}</strong></div>
              <div>{orderDate?.toLocaleString() ?? t('checkout.todayAsap')}</div>
            </IonLabel>
            <IonButton slot="end" size="large" color="edit">{t('checkout.edit')}</IonButton>
          </IonItem>
          {firm.uses_coupons && <IonItem lines="full" onClick={() => onClick('discount')}>
            <IonIcon slot="start" icon={ticketOutline} />
            <IonLabel>
              <div><strong>{t('checkout.discount')}</strong></div>
              <div>{order.coupon_code ?? t('checkout.noDiscount')}</div>
            </IonLabel>
            <IonButton slot="end" size="large" color="edit">{t('checkout.edit')}</IonButton>
          </IonItem>}
          <IonItem lines="full" onClick={() => onClick('remarks')}>
            <IonIcon slot="start" icon={createOutline} />
            <IonLabel>
              <div><strong>{t('checkout.remarks')}</strong></div>
              <div>{order.comment ?? t('checkout.noRemarks')}</div>
            </IonLabel>
            <IonButton slot="end" size="large" color="edit">{t('checkout.edit')}</IonButton>
          </IonItem>
          {firm.checkout_options.length > 0 && <IonItem lines="full">
            <IonIcon slot="start" icon={star} />
            <IonLabel>
              <div><strong>{t('checkout.extraOptions')}</strong></div>
              {firm.checkout_options.map(option => <div key={option.id} className="eo-inline-checkbox">
                <IonCheckbox checked={order.checkout_options?.find(({ id }) => id === option.id)?.selected ?? firm.checkout_options.find(({ id }) => id === option.id)?.selected ?? false} onIonChange={ev => dispatch(setCheckoutOption({ ...option, selected: ev.detail.checked }))} /> {option.name} {option.price > 0 && <span>&nbsp;({currency?.symbol} {option.price.toFixed(2)})</span>}
              </div>)}
            </IonLabel>
          </IonItem>}
          <IonItem lines="full">
            <IonIcon slot="start" icon={cardOutline} />
            <IonLabel>
              <div><strong>{t('checkout.paymentMethod')}</strong></div>
              {paymentMethods.map(method => (
                <IonChip key={method.payment_method_id} outline onClick={() => dispatch(setPaymentMethod(method))}>
                  <IonIcon icon={paymentMethodId === method.payment_method_id ? radioButtonOn : radioButtonOff}/>
                  <IonLabel style={{ textTransform: 'capitalize' }}>{method.payment_method}</IonLabel>
                </IonChip>
              ))}
            </IonLabel>
          </IonItem>
        </IonList>
        <div slot="fixed" className="eo-cart-sidebar ion-padding" style={{ width: '350px' }}>
          <div className="cart-body">
            <Cart />
          </div>
          <div className="cart-footer">
            <div className="total-item total-item-sub">
              <div>{t('cart.subtotal')}</div>
              <div>{currency?.symbol} {cartTotal ? cartTotal.toFixed(2) : '—'}</div>
            </div>
            <div className="total-item total-item-sub">
              <div>{t('cart.discounts')}</div>
              <div>{currency?.symbol} {cartDiscount ? cartDiscount.toFixed(2) : '—'}</div>
            </div>
            <div className="total-item total-item-sum">
              <div>{t('cart.total')}</div>
              <div>{currency?.symbol} {cartTotal ? cartTotalDiscounted.toFixed(2) : '—'}</div>
            </div>
            {cartErrors.length > 0 ? <>
              <IonButton expand="block" mode="md" color="medium" id="top-center">Submit order</IonButton>
              <IonPopover keepContentsMounted={true} trigger="top-center" side="top" alignment="center" showBackdrop={false}>
                <IonContent class="ion-padding">{cartErrors[0]}</IonContent>
              </IonPopover>
            </> : <IonButton expand="block" mode="md" disabled={orderLoading} onClick={submitOrder}>
              {orderLoading ? <IonSpinner/> : t('checkout.submitOrder')}
            </IonButton>}
          </div>
        </div>
      </IonContent>}
    </IonModal>
  )
}

export default CheckoutModal
