import { createSlice } from '@reduxjs/toolkit'
import { Address, Customer } from '@eo-storefronts/eo-core'
import { getAddresses, getOrderCount, searchCustomers } from './thunks'

export const addressesSlice = createSlice({
  name: 'addresses',
  initialState: {
    loading: false,
    query: '',
    results: [] as Address[]
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(getAddresses.pending, (state) => {
      state.loading = true
      state.results = []
    })
    builder.addCase(getAddresses.fulfilled, (state, action) => {
      state.results = action.payload
      state.loading = false
    })
  }
})

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    loading: false,
    orderCount: null as number | null,
    query: '',
    results: [] as Customer[]
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(searchCustomers.pending, (state, action) => {
      state.query = action.meta.arg
      state.loading = true
      state.results = []
    })
    builder.addCase(searchCustomers.fulfilled, (state, action) => {
      if (action.meta.arg === state.query) {
        state.loading = false
        state.results = action.payload as Customer[]
      }
    })
    builder.addCase(getOrderCount.pending, (state) => {
      state.orderCount = null
    })
    builder.addCase(getOrderCount.fulfilled, (state, action) => {
      state.orderCount = action.payload
    })
  }
})

export const addresses = addressesSlice.reducer
export const customers = customersSlice.reducer
