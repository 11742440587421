import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonSpinner,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { setDeliveryMethod } from '../stores/cart/slices'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { Fragment } from 'react'
import { locationOutline, personCircleOutline, restaurantOutline } from 'ionicons/icons'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectCustomer, selectOrder, selectOrderAddress } from '../stores/cart/selectors'
import { useAppDispatch } from '../stores'
import { selectDeliveryMethods } from '../stores/firm/selectors'
import { selectCustomerOrderCount } from '../stores/customers/selectors'

interface Props {
  isOpen: boolean,
  onClose(): void,
}

const ChangeDeliveryModal: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [ order ] = useSelector(selectOrder)
  const customer = useSelector(selectCustomer)
  const deliveryMethods = useSelector(selectDeliveryMethods)
  const orderAddress = useSelector(selectOrderAddress)
  const orderCount = useSelector(selectCustomerOrderCount)

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} className="change-delivery-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('checkout.changeDeliveryMethod')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonRadioGroup value={order.delivery_method} onIonChange={event => dispatch(setDeliveryMethod(event.detail.value as DeliveryMethodNames))}>
            {deliveryMethods.map(deliveryMethod => <Fragment key={deliveryMethod.key}>
              <IonItem lines={order.delivery_method === deliveryMethod.key ? 'none' : 'full'}>
                <IonLabel>{t(`deliveryMethods.${deliveryMethod.key}`)}</IonLabel>
                <IonRadio slot="end" value={deliveryMethod.key}></IonRadio>
              </IonItem>
              {order.delivery_method === deliveryMethod.key && <>
                <IonItem lines={order.delivery_method === DeliveryMethodNames.PICKUP ? 'full' : 'none'}>
                  <IonIcon slot="start" icon={personCircleOutline} />
                  <div className="eo-order-container" style={{ maxWidth: '300px' }}>
                    {customer ? <div>
                      <div>{customer.first_name} {customer.last_name}</div>
                      <div>{customer.phone ?? t('checkout.noPhone')} &mdash; {customer.email ?? t('checkout.noEmail')}</div>
                      <div>{orderCount === null && <IonSpinner name="dots" style={{ height: '16px', verticalAlign: 'bottom' }} />} {t('checkout.pastOrders', { count: orderCount ?? undefined })}</div>
                    </div> : t('checkout.noCustomerSelected')}
                  </div>
                </IonItem>
                {order.delivery_method === DeliveryMethodNames.DELIVERY && <IonItem lines="full">
                  <IonIcon slot="start" icon={locationOutline}/>
                  <div className="eo-order-container" style={{ maxWidth: '300px' }}>{orderAddress || t('checkout.noAddressSelected')}</div>
                </IonItem>}
                {order.delivery_method === DeliveryMethodNames.ON_THE_SPOT && <IonItem lines="full">
                  <IonIcon slot="start" icon={restaurantOutline}/>
                  <div className="eo-order-container" style={{ maxWidth: '300px' }}>{order.table_number || t('checkout.noTableSelected')}</div>
                </IonItem>}
              </>}
            </Fragment>)}
          </IonRadioGroup>
        </IonList>
      </IonContent>
      <IonFooter className="ion-padding">
        <IonButton expand="block" mode="md" onClick={onClose}>{t('modal.save')}</IonButton>
      </IonFooter>
    </IonModal>
  )
}

export default ChangeDeliveryModal
