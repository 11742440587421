import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent, IonFooter,
  IonGrid,
  IonHeader, IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonRow, IonSelect, IonSelectOption, IonText, IonTextarea,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCountries } from '../stores/firm/selectors'
import { Customer, ManagerService } from '@eo-storefronts/eo-core'
import useAutocomplete from '../hooks/useAutocomplete'
import { FormErrors } from '../types/forms'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean,
  onClose(): void,
  onCreate(customer: Customer): void,
  query?: string,
}

type FormFields = 'first_name' | 'last_name' | 'phone' | 'email' | 'street' | 'house_number' | 'zip_code' | 'locality' | 'country_id' | 'description'

const CreateCustomerModal: React.FC<Props> = ({ isOpen, onClose, onCreate, query }: Props) => {
  const countries = useSelector(selectCountries)
  const [ errors, setErrors ] = useState<FormErrors<FormFields> | null>(null)
  const countryInput = useRef<HTMLIonSelectElement>(null)
  const form = useRef<HTMLFormElement>(null)
  const { ref: streetInput, setup: setupAutocomplete } = useAutocomplete()
  const { t } = useTranslation()

  const onPresent = async () => {
    const currentForm = form.current as HTMLFormElement

    if (/^[0-9 ]+$/.test(query ?? '')) {
      currentForm.phone.value ||= query
    } else if (/.@/.test(query ?? '')) {
      currentForm.email.value ||= query
    }

    setupAutocomplete((input, components) => {
      const currentCountryInput = countryInput.current as HTMLIonSelectElement
      const currentForm = form.current as HTMLFormElement
      currentForm.street.value = components.route?.long_name ?? ''
      currentForm.house_number.value = components.street_number?.long_name ?? ''
      currentForm.zip_code.value = components.postal_code?.long_name ?? ''
      currentForm.locality.value = components.locality?.long_name ?? ''
      currentCountryInput.value = countries.find(country => country.iso === components.country?.short_name)?.id ?? ''
    })
  }

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()
    try {
      const formData = new FormData(event.target as HTMLFormElement)
      formData.set('last_name', formData.get('last_name') || 'Guest')
      onCreate(await ManagerService.createCustomer(formData))
    } catch (e: any) {
      setErrors((e as any).errors ?? {})
    }
  }

  useEffect(() => {
    if (isOpen) {
      setErrors(null)
    }
  }, [ isOpen ])

  return (
    <IonModal isOpen={isOpen} onDidPresent={onPresent} onDidDismiss={onClose} className="create-customer-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('customer.createCustomer')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form ref={form} id="create-customer-form" className="eo-form" onSubmit={onSubmit}>
          {errors && <div className="ion-padding"><IonText color="danger">{t('formErrors')}</IonText></div>}
          <IonGrid>
            <IonRow>
              <IonCol size="6">
                <IonItem lines="none" className={errors?.first_name ? 'ion-invalid' : undefined}>
                  <IonLabel position="stacked">{t('customer.firstName')}</IonLabel>
                  <IonInput name="first_name" />
                  <IonNote slot="error">{errors?.first_name?.join()}</IonNote>
                </IonItem>
              </IonCol>
              <IonCol size="6">
                <IonItem lines="none" className={errors?.last_name ? 'ion-invalid' : undefined}>
                  <IonLabel position="stacked">{t('customer.lastName')}</IonLabel>
                  <IonInput name="last_name" />
                  <IonNote slot="error">{errors?.last_name?.join()}</IonNote>
                </IonItem>
              </IonCol>
              <IonCol size="6">
                <IonItem lines="none" className={errors?.phone ? 'ion-invalid' : undefined}>
                  <IonLabel position="stacked">{t('customer.phoneNumber')}</IonLabel>
                  <IonInput name="phone" />
                  <IonNote slot="error">{errors?.phone?.join()}</IonNote>
                </IonItem>
              </IonCol>
              <IonCol size="6">
                <IonItem lines="none" className={errors?.email ? 'ion-invalid' : undefined}>
                  <IonLabel position="stacked">{t('customer.emailAddress')}</IonLabel>
                  <IonInput type="email" name="email" />
                  <IonNote slot="error">{errors?.email?.join()}</IonNote>
                </IonItem>
              </IonCol>
              <IonCol size="9">
                <IonItem lines="none" className={errors?.street ? 'ion-invalid' : undefined}>
                  <IonLabel position="stacked">{t('address.street')}</IonLabel>
                  <IonInput ref={streetInput} name="street" />
                  <IonNote slot="error">{errors?.street?.join()}</IonNote>
                </IonItem>
              </IonCol>
              <IonCol size="3">
                <IonItem lines="none" className={errors?.house_number ? 'ion-invalid' : undefined}>
                  <IonLabel position="stacked">{t('address.number')}</IonLabel>
                  <IonInput name="house_number" />
                  <IonNote slot="error">{errors?.house_number?.join()}</IonNote>
                </IonItem>
              </IonCol>
              <IonCol size="3">
                <IonItem lines="none" className={errors?.zip_code ? 'ion-invalid' : undefined}>
                  <IonLabel position="stacked">{t('address.zip')}</IonLabel>
                  <IonInput name="zip_code" />
                  <IonNote slot="error">{errors?.zip_code?.join()}</IonNote>
                </IonItem>
              </IonCol>
              <IonCol size="9">
                <IonItem lines="none" className={errors?.locality ? 'ion-invalid' : undefined}>
                  <IonLabel position="stacked">{t('address.city')}</IonLabel>
                  <IonInput name="locality" />
                  <IonNote slot="error">{errors?.locality?.join()}</IonNote>
                </IonItem>
              </IonCol>
              <IonCol size="12">
                <IonItem lines="none" className={errors?.country_id ? 'ion-invalid' : undefined}>
                  <IonLabel position="stacked">{t('address.country')}</IonLabel>
                  <IonSelect ref={countryInput} name="country_id" interface="popover">
                    {countries.map(country => <IonSelectOption key={country.id} value={country.id}>{country.name}</IonSelectOption>)}
                  </IonSelect>
                  <IonNote slot="error">{errors?.country_id?.join()}</IonNote>
                </IonItem>
              </IonCol>
              <IonCol size="12">
                <IonItem lines="none" className={errors?.description ? 'ion-invalid' : undefined}>
                  <IonLabel position="stacked">{t('address.remarks')}</IonLabel>
                  <IonTextarea name="description" rows={2} />
                  <IonNote slot="error">{errors?.description?.join()}</IonNote>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
      <IonFooter className="ion-padding">
        <IonButton expand="block" mode="md" type="submit" form="create-customer-form">{t('customer.createCustomer')}</IonButton>
      </IonFooter>
    </IonModal>
  )
}

export default CreateCustomerModal
