import { useEffect, useRef } from 'react'

export default (contentLength: number) => {
  const ionContentRef = useRef<HTMLIonContentElement>(null)
  const prevCountRef = useRef(0)

  useEffect(() => {
    if (contentLength > (prevCountRef.current ?? contentLength)) {
      setTimeout(async () => ionContentRef.current?.scrollToBottom(500), 50)
    }

    prevCountRef.current = contentLength
  }, [ contentLength ])

  return ionContentRef
}
