import { createAsyncThunk } from '@reduxjs/toolkit'
import { Id, ManagerService } from '@eo-storefronts/eo-core'

export const getAddresses = createAsyncThunk(
  'customers/getAddresses',
  async (customerId: Id) => {
    return ManagerService.getCustomerAddresses(customerId)
  }
)

export const getOrderCount = createAsyncThunk(
  'customers/getOrderCount',
  async (customerId: Id) => {
    return ManagerService.getCustomerOrderCount(customerId)
  }
)

export const searchCustomers = createAsyncThunk(
  'customers/search',
  async (query: string) => {
    if (!query) {
      return []
    }

    return ManagerService.searchCustomers(query)
  }
)
