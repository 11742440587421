import { createAsyncThunk } from '@reduxjs/toolkit'
import { ApiConfiguration, AuthService } from '@eo-storefronts/eo-core'
import { Preferences } from '@capacitor/preferences'

export const loginAsGuest = createAsyncThunk(
  'user/fetchByIdStatus',
  async (firmId: string) => {
    ApiConfiguration.addOrReplaceHeader('firmId', firmId)

    const clientToken = await AuthService.loginAsClient({
      client_id: 'app',
      client_secret: 'VhtDuF5GQjXyMu3Md8XTKuys3t4vy8astxOAGCeE'
    })
    const guestToken = await AuthService.loginAsGuest()

    return { clientToken, guestToken }
  }
)

export const loginAsManager = createAsyncThunk(
  'user/loginAsManager',
  async (auth: any) => {
    return AuthService.loginAsClient({
      client_id: auth.username,
      client_secret: auth.password
    })
  }
)

export const refreshToken = createAsyncThunk(
  'user/refreshToken',
  async () => {
    const authPreference = await Preferences.get({ key: 'auth' })

    if (!authPreference.value) {
      throw new Error('Auth setting not available')
    }

    const auth = JSON.parse(authPreference.value)

    return AuthService.loginAsClient({
      client_id: auth.username,
      client_secret: auth.password
    })
  }
)
