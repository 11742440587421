"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var isMobileWeb = function (win) { return isMobile(win) && !isHybrid(win); };
var isIpad = function (win) {
    // iOS 12 and below
    if (testUserAgent(win, /iPad/i)) {
        return true;
    }
    // iOS 13+
    return testUserAgent(win, /Macintosh/i) && isMobile(win);
};
var isIphone = function (win) { return testUserAgent(win, /iPhone/i); };
var isIOS = function (win) { return testUserAgent(win, /iPhone|iPod/i) || isIpad(win); };
var isAndroid = function (win) { return testUserAgent(win, /android|sink/i); };
var isAndroidTablet = function (win) {
    return isAndroid(win) && !testUserAgent(win, /mobile/i);
};
var isPhablet = function (win) {
    var width = win.innerWidth;
    var height = win.innerHeight;
    var smallest = Math.min(width, height);
    var largest = Math.max(width, height);
    return smallest > 390 && smallest < 520 && largest > 620 && largest < 800;
};
var isTablet = function (win) {
    var width = win.innerWidth;
    var height = win.innerHeight;
    var smallest = Math.min(width, height);
    var largest = Math.max(width, height);
    return isIpad(win) || isAndroidTablet(win) || (smallest > 460 && smallest < 820 && largest > 780 && largest < 1400);
};
var isMobile = function (win) { return matchMedia(win, '(any-pointer:coarse)'); };
var isDesktop = function (win) { return !isMobile(win); };
var isHybrid = function (win) { return isCordova(win) || isCapacitorNative(win); };
var isCordova = function (win) { return !!(win['cordova'] || win['phonegap'] || win['PhoneGap']); };
var isCapacitorNative = function (win) {
    var capacitor = win['Capacitor'];
    return !!(capacitor === null || capacitor === void 0 ? void 0 : capacitor.isNative);
};
var isElectron = function (win) { return testUserAgent(win, /electron/i); };
var isPWA = function (win) { var _a; return (((_a = win.matchMedia) === null || _a === void 0 ? void 0 : _a.call(win, '(display-mode: standalone)').matches) || win.navigator.standalone); };
var testUserAgent = function (win, expr) { return expr.test(win.navigator.userAgent); };
var matchMedia = function (win, query) { var _a; return (_a = win.matchMedia) === null || _a === void 0 ? void 0 : _a.call(win, query).matches; };
var PlatformUtils = function (win) {
    if (win === void 0) { win = window; }
    return ({
        isIpad: isIpad(win),
        isIphone: isIphone(win),
        isIOS: isIOS(win),
        isAndroid: isAndroid(win),
        isPhablet: isPhablet(win),
        isTablet: isTablet(win),
        isCordova: isCordova(win),
        isCapacitorNative: isCapacitorNative(win),
        isElectron: isElectron(win),
        isPWA: isPWA(win),
        isMobile: isMobile(win),
        isMobileWeb: isMobileWeb(win),
        isDesktop: isDesktop(win),
        isHybrid: isHybrid(win)
    });
};
exports.default = PlatformUtils;
