import './Cart.css'
import { IonContent, IonList, useIonActionSheet } from '@ionic/react'
import useAnimateAppend from '../hooks/useAnimateAppend'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../hooks/useLanguage'
import { useAppDispatch } from '../stores'
import { selectCart } from '../stores/cart/selectors'
import { updatePrice, updateQuantity } from '../stores/cart/slices'
import { CartItem } from './CartItem'

const Cart: React.FC<{ showEdit?: boolean }> = ({ showEdit }) => {
  const { t } = useTranslation()
  const [ present ] = useIonActionSheet()
  const dispatch = useAppDispatch()
  const language = useLanguage()
  const { cart } = useSelector(selectCart)
  const ionContentRef = useAnimateAppend(cart.length)

  const updateCount = (index: number, count: number) => {
    if (count) {
      dispatch(updateQuantity({ index, quantity: count }))

      return
    }

    present({
      header: t('deleteProductConfirmation.header'),
      subHeader: cart[index].product.name[language] as string,
      buttons: [
        {
          text: t('deleteProductConfirmation.confirm'),
          role: 'destructive',
          data: { action: 'delete' }
        },
        {
          text: t('deleteProductConfirmation.cancel'),
          role: 'cancel',
          data: { action: 'cancel' }
        }
      ],
      onDidDismiss: ({ detail: { data } }: any) => {
        if (data?.action === 'delete') {
          dispatch(updateQuantity({ index, quantity: count }))
        }
      }
    })
  }

  return (
    <IonContent ref={ionContentRef} color="white" className="eo-cart">
      <IonList>
        {cart.map((item, index) => <CartItem key={index} index={index}
          onUpdateCount={count => updateCount(index, count)}
          onUpdatePrice={totalPrice => dispatch(updatePrice({ index, totalPrice }))}
          showEdit={showEdit ?? false}
          product={cart[index].product} modifiers={cart[index].modifiers}
          quantity={cart[index].quantity} totalPrice={cart[index].totalPrice} />)}
      </IonList>
    </IonContent>
  )
}

export default Cart
