import { CSSProperties, useEffect, useRef, useState } from 'react'
import { Product } from '@eo-storefronts/eo-core'

const getOffset = async (element: HTMLElement, reference: HTMLElement) => {
  const parent = reference.offsetParent as HTMLElement
  let elem: HTMLElement | null = element
  let offset = [ -reference.offsetLeft, -reference.offsetTop ]

  do {
    if ((elem.offsetParent as Element).tagName === 'ION-CONTENT' && elem.getAttribute('slot') !== 'fixed') {
      const ionScroll = await (elem.offsetParent as HTMLIonContentElement).getScrollElement()
      offset = [ offset[0] + elem.offsetLeft - ionScroll.scrollLeft, offset[1] + elem.offsetTop - ionScroll.scrollTop ]
    } else {
      offset = [ offset[0] + elem.offsetLeft - elem.scrollLeft, offset[1] + elem.offsetTop - elem.scrollTop ]
    }
    elem = elem.offsetParent as HTMLElement
  } while (elem && elem !== parent)

  return offset
}

const AddToCartAnimation: React.FC = () => {
  const [ key, setKey ] = useState(0)
  const [ src, setSrc ] = useState(undefined as string | undefined)
  const [ styles, setStyles ] = useState<CSSProperties>({ display: 'none' })
  const [ timeoutId, setTimeoutId ] = useState(0)
  const divRef = useRef<HTMLDivElement>(null)

  const animate = async () => {
    const cartElement = document.querySelector('.eo-cart-sidebar .cart-body') as HTMLElement
    const offset = await getOffset(cartElement, divRef.current as HTMLDivElement)

    setStyles({ ...styles, opacity: '0', left: `${offset[0]}px`, top: `${offset[1] + cartElement.offsetHeight / 2}px` })
    clearInterval(timeoutId)
    setTimeoutId(window.setTimeout(() => {
      setStyles({ display: 'none' })
    }, 500))
  }

  const onProductAdded = async (product: Product) => {
    const imgElement: HTMLImageElement | null = document.querySelector(`.animate-product-${product.id} img`)

    if (!imgElement) {
      return
    }

    const offset = await getOffset(imgElement, divRef.current as HTMLDivElement)

    setSrc(imgElement.src)
    setStyles({ opacity: '1', left: `${offset[0]}px`, top: `${offset[1]}px`, width: `${imgElement.width}px`, height: `${imgElement.height}px` })
    setKey(Date.now())
  }

  useEffect(() => {
    const listener: EventListener = (event) => { onProductAdded((event as CustomEvent<Product>).detail) }

    document.addEventListener('productAdded', listener)

    return () => document.removeEventListener('productAdded', listener)
  }, [])

  return (
    <div ref={divRef} style={{ position: 'relative' }}>
      <img key={key} onLoad={animate} style={{ position: 'absolute', zIndex: '1', transition: 'left 0.5s ease, top 0.5s ease, opacity 0.5s ease-in', ...styles }} src={src} />
    </div>
  )
}

export default AddToCartAnimation
