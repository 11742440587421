import { Worldline, WorldlineTerminal } from '@easyorderapp/capacitor-plugin-worldline'
import { useAppDispatch } from '../stores'
import { addTerminal, removeTerminal } from '../stores/terminals/slices'
import { useIonToast } from '@ionic/react'
import { useEffect } from 'react'

export const getInfo = async () => ({
  addresses: await Worldline.getListenAddresses()
    .then(({ value }) => value.filter(address => /^\d+\.\d+\.\d+\.\d+$/.test(address))),
  version: await Worldline.getLibVersion()
    .then(({ value }) => value)
})

export default () => {
  const dispatch = useAppDispatch()
  const [ present ] = useIonToast()

  useEffect(() => {
    Worldline.getConnectedTerminals().then(async ({ value }) => {
      for (const terminal of value) {
        await dispatch(removeTerminal(terminal.terminalId))
        await dispatch(addTerminal({
          id: terminal.terminalId,
          name: terminal.model ?? 'Worldline terminal',
          type: 'worldline'
        }))
      }
    })

    const listenerConnect = Worldline.addListener('onTerminalConnect', (terminal: WorldlineTerminal) => {
      dispatch(addTerminal({
        id: terminal.terminalId,
        name: terminal.model ?? 'Worldline terminal',
        type: 'worldline'
      }))
      present({ message: `Terminal ${terminal.model} connected`, duration: 3000 })
    })

    const listenerDisconnect = Worldline.addListener('onTerminalDisconnect', (terminal: WorldlineTerminal) => {
      dispatch(removeTerminal(terminal.terminalId))
      present({ message: `Terminal ${terminal.model} disconnected`, duration: 3000 })
    })

    return function cleanup() {
      listenerConnect.then(async ({ remove }) => remove())
      listenerDisconnect.then(async ({ remove }) => remove())
    }
  }, [])
}
