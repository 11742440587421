import {
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterLink,
  IonRouterOutlet,
  IonSplitPane
} from '@ionic/react'
import { Preferences } from '@capacitor/preferences'

import { Redirect, Route, useLocation } from 'react-router-dom'
import {
  logOutOutline,
  settingsOutline,
  statsChartOutline
} from 'ionicons/icons'
import './Menu.css'
import Login from '../pages/Login'
import Products from '../pages/Products'
import ProductConfig from '../pages/ProductConfig'
import Settings from '../pages/Settings'
import useWorldline from '../hooks/useWorldline'
import { useEffect } from 'react'
import { useAppDispatch } from '../stores'
import { refreshToken } from '../stores/user/thunks'
import { useTranslation } from 'react-i18next'

const Menu: React.FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { t } = useTranslation()
  const logout = async () => {
    await Preferences.remove({ key: 'auth' })
    window.location.href = '/login'
  }

  useWorldline()

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(refreshToken())
    }, 300000)

    return () => clearInterval(interval)
  }, [])

  return (
    <IonSplitPane disabled={location.pathname === '/login'} contentId="main" style={{ '--side-min-width': '64px', '--side-max-width': '64px' }}>
      <IonMenu contentId="main" type="overlay">
        <IonContent>
          <IonRouterLink routerLink="/products" routerDirection="none">
            <img className="logo" alt="EasyOrder" src="assets/icon/icon.png" />
          </IonRouterLink>
        </IonContent>
        <IonFooter>
          <IonList lines="none">
            <IonItem href="https://dashboard.easyorderapp.com" target="_blank" detail={false}>
              <IonIcon slot="start" icon={statsChartOutline} />
              <IonLabel>{t('menu.dashboard')}</IonLabel>
            </IonItem>
            <IonMenuToggle autoHide={false}>
              <IonItem routerLink="/settings" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" icon={settingsOutline} />
                <IonLabel>{t('menu.settings')}</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonItem onClick={logout} detail={false}>
              <IonIcon slot="start" icon={logOutOutline} />
              <IonLabel>{t('menu.logout')}</IonLabel>
            </IonItem>
          </IonList>
        </IonFooter>
      </IonMenu>
      <IonRouterOutlet id="main">
        <Route path="/login" exact={true}>
          <Login />
        </Route>
        <Route path="/" exact={true}>
          <Redirect to="/login" />
        </Route>
        <Route path="/products" exact={true}>
          <Products />
        </Route>
        <Route path="/settings" exact={true}>
          <Settings />
        </Route>
        <Route path="/product/:id" exact={true}>
          <ProductConfig edit={false} />
        </Route>
        <Route path="/edit/:id" exact={true}>
          <ProductConfig edit={true} />
        </Route>
      </IonRouterOutlet>
    </IonSplitPane>
  )
}

export default Menu
