import { Coupon, Id, OrderService, Product } from '@eo-storefronts/eo-core'
import { useSelector } from 'react-redux'
import { selectCart } from '../stores/cart/selectors'

export default () => {
  const { cart, cartTotal } = useSelector(selectCart)

  return async (code: string) => {
    // TODO this should use withCustomer (COUPON_CODE_NOT_FOR_GUEST_USERS)
    const coupon = (await OrderService.checkCoupon(code, cartTotal))

    if (!coupon.valid) {
      throw coupon.error
    }

    switch (coupon.type) {
      case 'amount':
        break
      case 'percentage':
        coupon.value *= cartTotal / 100
        break
      case 'category': {
        const couponCategory = coupon.category
        if (!couponCategory) {
          throw 'Missing property category on coupon of type category'
        }

        // TODO in storefronts, this applies to first product added, not max value
        const item = cart.filter(item => item.product.categoryId === couponCategory.id).reduce(
          (agg, item) => item.product.price > agg.max ? { max: item.product.price, product: item.product } : agg,
          { max: 0, product: null as Product | null })

        if (!item.product) {
          throw `Coupon only applies to category ${couponCategory.name}`
        }

        (coupon as Coupon & { productId?: Id }).productId = item.product.id
        coupon.value = item.product.price
        break
      }
      case 'product': {
        const couponProduct = coupon.product
        if (!couponProduct) {
          throw 'Missing property product on coupon of type product'
        }

        const product = cart.filter(item => item.product.id === couponProduct.id)[0]

        if (!product) {
          throw `Coupon only applies to product ${couponProduct.name}`
        }

        (coupon as Coupon & { productId?: Id }).productId = couponProduct.id
        coupon.value = couponProduct.price
        break
      }
      default:
        throw `Unsupported coupon type ${coupon.type}`
    }

    return coupon
  }
}
