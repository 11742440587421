import React, { Fragment } from 'react'
import { IonText } from '@ionic/react'
import { useLanguage } from '../hooks/useLanguage'
import { useSelector } from 'react-redux'
import { selectCurrency } from '../stores/firm/selectors'
import { Modifier } from '@eo-storefronts/eo-core'

type Props = {
  modifiers: {
    modifier: Modifier,
    quantity: number,
  }[],
};

export const ProductModifiers: React.FC<Props> = ({ modifiers }) => {
  const language = useLanguage()
  const currency = useSelector(selectCurrency)

  return (
    <div>
      <IonText color="medium">{modifiers.map(({ modifier, quantity }, index, all) => {
        const El = quantity < 1 ? 's' : 'span'
        return (
          <Fragment key={index}>
            <El>
              {quantity > 1 && `${quantity}x`}
              {`${modifier.name[language]}${modifier.price && ` (${currency?.symbol} ${modifier.price.toFixed(2)})` || ''}`}
            </El>
            {all.length - 1 > index && ', '}
          </Fragment>
        )
      })}</IonText>
    </div>
  )
}
