import { Product } from '@eo-storefronts/eo-core'
import { IonThumbnail } from '@ionic/react'
import React from 'react'
import { useLanguage } from '../hooks/useLanguage'

type Props = {
  product: Product,
  slot?: string | undefined,
};

export const ProductThumbnail: React.FC<Props> = ({ product, slot }) => {
  const language = useLanguage()

  return (
    <>
      {product.images.length > 0 && <IonThumbnail slot={slot}>
        <img alt={product.name[language] || undefined} src={product.images[0].thumb} />
      </IonThumbnail>}
    </>
  )
}
