import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonMenuButton,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonRouter
} from '@ionic/react'
import { Modifier } from '@eo-storefronts/eo-core'
import { close } from 'ionicons/icons'
import { useParams } from 'react-router-dom'
import { useEffect, useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useLanguage } from '../hooks/useLanguage'
import { selectModifierGroups, selectModifiers, selectProducts } from '../stores/products/selectors'
import { getQuantity } from '../stores/cart/slices'
import './ProductConfig.css'
import { selectCartItems } from '../stores/cart/selectors'
import { selectCurrency } from '../stores/firm/selectors'
import { useAddToCart, useAmendCart } from '../hooks/useAddToCart'
import useAnimateAppend from '../hooks/useAnimateAppend'
import { useTranslation } from 'react-i18next'
import { ProductModifierCard } from '../components/ProductModifierCard'

type Props = {
  edit: boolean,
};

const ProductConfig: React.FC<Props> = ({ edit }) => {
  const addToCart = useAddToCart()
  const amendCart = useAmendCart()
  const router = useIonRouter()
  const language = useLanguage()
  const { id } = useParams<{ id: string }>()
  const cartItems = useSelector(selectCartItems)
  const currency = useSelector(selectCurrency)
  const modifierGroups = useSelector(selectModifierGroups)
  const modifiers = useSelector(selectModifiers)
  const products = useSelector(selectProducts)
  const { t } = useTranslation()
  const [ comments, setComments ] = useState('')
  const [ state, setState ] = useState({
    page: 0,
    items: {} as Record<string, number>
  })
  const ionContentRef = useAnimateAppend(Object.values(state.items).length)
  const product = products[edit ? cartItems[parseInt(id)].productId : id]
  const productModifierGroups = product.modifierGroups
    .map(id => modifierGroups[id])
    .filter(group => group.type === 'selection')
    .sort((a, b) => a.sort - b.sort)

  const totalPrice = Object.keys(state.items).reduce((sum, id) => sum + modifiers[id].price * state.items[id], product.price)

  const productModifierGroup = productModifierGroups[state.page]
  const productModifiers = productModifierGroup.modifiers.map(id => modifiers[id])

  const pages = productModifierGroups.map(group => ({
    group,
    modifiers: group.modifiers.map(id => modifiers[id]).map(modifier => ({
      modifier,
      quantity: getQuantity(state.items, modifier),
      action: Math.sign(state.items[modifier.id] || 0)
    }))
  }))

  const items = productModifiers.map(modifier => ({
    modifier,
    count: getQuantity(state.items, modifier)
  }))

  const selectedModifiersCount = items.reduce((sum, { count }) => sum + count, 0)
  const maxModifiersCount = productModifierGroup.isMultipleChoice ? productModifierGroup.multipleChoiceMax : 1
  const canAddModifier = !productModifierGroup.multipleChoiceMultiselectMax || selectedModifiersCount < productModifierGroup.multipleChoiceMultiselectMax
  const canNext = productModifierGroup.isMultipleChoice
    ? selectedModifiersCount >= (productModifierGroup.multipleChoiceMin || 0)
    : !productModifierGroup.isRequired || selectedModifiersCount > 0

  const changeCountInner = (stateItems: Record<string, number>, item: Modifier, count: number) => {
    if (count > 0 && !productModifierGroup.isMultipleChoice) {
      /*for (const other of items) {
        other.count && (stateItems = changeCountInner(stateItems, other.modifier, 0));
      }*/
      for (const other of productModifierGroup.modifiers.map(id => modifiers[id])) {
        getQuantity(stateItems, other) && (stateItems = changeCountInner(stateItems, other, 0))
      }
    }

    const relative = count - (item.isDefault ? 1 : 0)

    if (relative === 0) {
      return Object.fromEntries(Object.entries(stateItems).filter(([ id ]) => id !== item.id))
    }

    return { ...stateItems, [item.id]: relative }
  }

  const changeCount = (item: Modifier, count: number) => {
    setStateAndNext({ ...state, items: changeCountInner(state.items, item, count) })
  }

  const onBack = () => {
    if (state.page === 0) {
      router.push('/products', 'back', 'pop')
      return
    }

    setState({ ...state, page: state.page - 1 })
  }

  const onNext = () => {
    setState({ ...state, page: onNextInner() })
  }

  const onNextInner = () => {
    if ((state.page + 1) < productModifierGroups.length) {
      return state.page + 1
    } 
    setTimeout(async () => {
      if (edit) {
        return amendCart(parseInt(id), state.items, comments || undefined)
      }

      return addToCart(product, state.items, comments || undefined)
    }, 100)
    router.push('/products', 'back', 'pop')
    return state.page
  }

  const setStateAndNext = (newState: typeof state) => {
    const newSelectedModifiersCount = productModifiers.reduce((sum, modifier) => sum + getQuantity(newState.items, modifier), 0)

    if (productModifierGroup.isRequired && newSelectedModifiersCount === maxModifiersCount) {
      setState({ ...newState, page: onNextInner() })
    } else {
      setState(newState)
    }
  }

  useEffect(() => {
    if (edit) {
      const cartItem = cartItems[parseInt(id)]
      setComments(cartItem.comment ?? '')
      setState({ page: 0, items: cartItem.modifiers })
    }
  }, [ edit ])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{product.name[language]}</IonTitle>
          <IonButtons slot="primary">
            <IonButton routerLink="/products" routerDirection="back">
              <IonIcon slot="icon-only" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent style={{ '--padding-end': '350px' }}>
        <h1 className="ion-padding">{productModifierGroup.name[language]}</h1>
        <IonGrid>
          <IonRow>
            {items.map(({ modifier, count }) => (
              <IonCol size="3" key={modifier.id}>
                <ProductModifierCard
                  canAdd={canAddModifier}
                  group={productModifierGroup}
                  modifier={modifier}
                  onChange={value => changeCount(modifier, value)}
                  quantity={count} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

        <div slot="fixed" className="eo-cart-sidebar ion-padding" style={{ width: '350px' }}>
          <dl>
            <dt style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{product.name[language]}</dt>
            <dd style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{currency?.symbol} {product.price.toFixed(2)}</dd>
          </dl>

          <IonContent ref={ionContentRef} color="white" className="cart-body">
            <dl>
              {pages.filter((page, index) => index <= state.page).map(({ modifiers }, index) => <Fragment key={index}>
                {modifiers.filter(({ quantity }) => quantity).map(({ action, modifier, quantity }) => {
                  const El = action < 0 ? 's' : 'span'

                  return (
                    <IonText color="medium" key={modifier.id}>
                      <dt><El>{quantity > 1 && `${quantity}x `}{modifier.name[language]}</El></dt>
                      {modifier.price > 0 && <dd>{currency?.symbol} {modifier.price.toFixed(2)}</dd>}
                    </IonText>
                  )
                })}
              </Fragment>)}
            </dl>
          </IonContent>

          <div className="cart-footer">
            <IonItem className="comment" lines="none">
              <IonTextarea
                placeholder={t('cart.comments') as string}
                rows={3}
                value={comments}
                onIonChange={(ev) => setComments(String(ev.target.value))}/>
            </IonItem>

            <div className="total-item total-item-sum">
              <div>{t('cart.total')}</div>
              <div>{currency?.symbol} {totalPrice.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <div slot="start">
            <IonButton mode="md" onClick={onBack}>{t('cart.back')}</IonButton>
          </div>
          <div slot="end" style={{ width: '342px' }}>
            <IonButton expand="block" mode="md" disabled={!canNext} onClick={onNext}>{(state.page + 1) < productModifierGroups.length ? t('cart.next') : t('cart.addToCart', { currency: currency?.symbol, price: totalPrice.toFixed(2) })}</IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default ProductConfig
