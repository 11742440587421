import {
  IonButton,
  IonButtons, IonChip,
  IonContent, IonDatetime, IonDatetimeButton,
  IonFooter,
  IonHeader, IonIcon, IonItem, IonLabel, IonList,
  IonModal, IonRadio,
  IonRadioGroup, IonSpinner,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../stores'
import { loadTimeslots } from '../stores/timeslots/thunks'
import { selectTimeslots } from '../stores/timeslots/selectors'
import { radioButtonOff, radioButtonOn } from 'ionicons/icons'
import { useSelector } from 'react-redux'
import { Id, Interval, Timeslot } from '@eo-storefronts/eo-core'
import { selectOrder, selectUseTimeslots } from '../stores/cart/selectors'
import { setDeliveryDate } from '../stores/cart/slices'
import { toLocalIsoString } from '../utils/dateUtils'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean,
  onClose(): void,
}

const TimeDateModal: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const useTimeslots = useSelector(selectUseTimeslots)
  const [ order, orderDate ] = useSelector(selectOrder)
  const { timeslots, timeslotsLoading } = useSelector(selectTimeslots)
  const { t } = useTranslation()
  const [ state, setState ] = useState({
    date: new Date(),
    timeslot_id: null as Id|null,
    mode: 'asap' as 'asap'|'custom'
  })

  const dateString = toLocalIsoString(state.date)
  const availableTimeslots = timeslots.map(item => ({ ...item, selected: item.interval.time === dateString.substring(11, 16) }))

  const onPresent = () => {
    setState({ date: orderDate ?? new Date(), timeslot_id: order.timeslot_id ?? null, mode: orderDate ? 'custom' : 'asap' })
  }

  const save = () => {
    dispatch(setDeliveryDate(state.mode === 'custom' ? { timestamp: state.date?.getTime(), timeslot_id: state.timeslot_id } : { timestamp: null, timeslot_id: null }))
    onClose()
  }

  const selectTimeslot = (item: {interval: Interval, timeslot: Timeslot}) => {
    const date = new Date(`${item.interval.date}T${item.interval.time}`)
    setState({ date: date, timeslot_id: item.timeslot.id, mode: 'custom' })
  }

  useEffect(() => {
    if (isOpen && useTimeslots) {
      dispatch(loadTimeslots(state.date))
    }
  }, [ isOpen, useTimeslots, dateString.substring(0, 10) ])

  return (
    <IonModal isOpen={isOpen} onIonModalWillPresent={onPresent} onDidDismiss={onClose} className="time-date-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('checkout.timeAndDate')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonRadioGroup value={state.mode} onIonChange={event => setState({ ...state, mode: event.detail.value })}>
            <IonItem lines="full">
              <IonLabel>{t('checkout.asap')}</IonLabel>
              <IonRadio slot="end" value="asap"></IonRadio>
            </IonItem>
            <IonItem lines="full">
              <IonLabel>{t('checkout.scheduleForLater')}</IonLabel>
              <IonRadio slot="end" value="custom"></IonRadio>
            </IonItem>
            {state.mode === 'custom' && <>
              <IonItem lines="full">
                <IonLabel>{t('checkout.date')}</IonLabel>
                <IonDatetimeButton key={dateString} datetime="date"></IonDatetimeButton>
              </IonItem>
              {useTimeslots ? <IonItem lines="none">
                {timeslotsLoading ? <IonLabel>
                  <IonSpinner/> {t('checkout.loadingTimeslots')}
                </IonLabel> : <IonLabel className="ion-text-wrap">
                  {availableTimeslots.map(item => (
                    <IonChip key={`${item.timeslot.id}/${item.interval.time}`} outline
                      onClick={() => selectTimeslot(item)}>
                      <IonIcon icon={item.selected ? radioButtonOn : radioButtonOff}/>
                      <IonLabel>{item.interval.time}</IonLabel>
                    </IonChip>
                  ))}
                </IonLabel>}
              </IonItem> : <IonItem lines="full">
                <IonLabel>{t('checkout.time')}</IonLabel>
                <IonDatetimeButton key={dateString} datetime="time"></IonDatetimeButton>
              </IonItem>}
            </>}
          </IonRadioGroup>
        </IonList>

        <IonModal keepContentsMounted={true}>
          <IonDatetime key={dateString} value={dateString} id="date" presentation="date" min={toLocalIsoString(new Date())} onIonChange={ev => {
            ev.target.confirm(true)
            setState({ ...state, date: new Date(ev.detail.value as string) })
          }} />
        </IonModal>

        <IonModal keepContentsMounted={true}>
          <IonDatetime key={dateString} value={dateString} id="time" presentation="time" showDefaultButtons={true} min={toLocalIsoString(new Date())} onIonChange={ev => {
            setState({ ...state, date: new Date(ev.detail.value as string) })
          }} />
        </IonModal>
      </IonContent>
      <IonFooter className="ion-padding">
        <IonButton expand="block" mode="md" disabled={state.mode === 'custom' && useTimeslots && availableTimeslots.filter(({ selected }) => selected).length === 0} onClick={save}>{t('modal.save')}</IonButton>
      </IonFooter>
    </IonModal>
  )
}

export default TimeDateModal
