import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { personCircleOutline } from 'ionicons/icons'
import { useAppDispatch } from '../stores'
import { useRef } from 'react'
import { Customer } from '@eo-storefronts/eo-core'
import { searchCustomers } from '../stores/customers/thunks'
import { SearchCustomerResults } from './SearchCustomerResults'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean,
  onClose(): void,
  onCreate(query: string): void,
  onSelect(customer: Customer): void,
}

const SearchCustomerModal: React.FC<Props> = ({ isOpen, onClose, onCreate, onSelect }: Props) => {
  const dispatch = useAppDispatch()
  const searchbarRef = useRef<HTMLIonSearchbarElement>(null)
  const { t } = useTranslation()

  const onDidPresent = async () => searchbarRef.current?.setFocus()

  return (
    <IonModal isOpen={isOpen} onDidPresent={onDidPresent} onDidDismiss={onClose} className="search-customer-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('checkout.findCustomer')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="search-customer-content">
          <IonSearchbar ref={searchbarRef} placeholder={t('checkout.searchCustomerPlaceholder') as string} debounce={500} onIonChange={async (ev) => dispatch(searchCustomers(String(ev.target.value)))} />
          <IonText color="medium">{t('checkout.results')}</IonText>
          <SearchCustomerResults onCreate={onCreate} onSelect={onSelect}>
            <div className="search-customer-empty">
              <div><IonIcon color="medium" icon={personCircleOutline}/></div>
              <div><IonText color="medium">{t('checkout.searchCustomerText')}</IonText></div>
            </div>
          </SearchCustomerResults>
        </div>
      </IonContent>
    </IonModal>
  )
}

export default SearchCustomerModal
