import { IonApp, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Provider } from 'react-redux'
import { ApiConfiguration } from '@eo-storefronts/eo-core'
import store from './stores'
import Menu from './components/Menu'
import './i18n'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/styles.css'

setupIonicReact({ mode: 'ios' })
// setupIonicReact();

ApiConfiguration.setHeaders({
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'apiVersion': '0.0.0',
  'appVersion': '0.0.0',
  'deviceModel': 'Web',
  'deviceOS': 'Web',
  'deviceOSVersion': 'Web'
})
// TODO use ApiConfigUtils from next-app
ApiConfiguration.addOrReplaceHeader('lang', 'en')
ApiConfiguration.addOrReplaceHeader('language', 'en')
ApiConfiguration.addOrReplaceHeader('Accept-language', 'en')

const App: React.FC = () => {
  return (
    <IonApp>
      <Provider store={store}>
        <IonReactRouter>
          <Menu />
        </IonReactRouter>
      </Provider>
    </IonApp>
  )
}

export default App
