import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { selectCategoryIds, selectProductIds } from '../cart/selectors'
import { FirmService } from '@eo-storefronts/eo-core'

export const loadTimeslots = createAsyncThunk.withTypes<{state: RootState}>()(
  'timeslots/load',
  async (date: Date, { getState }) => {
    const state = getState()
    const productIds = selectProductIds(state)
    const categoryIds = selectCategoryIds(state)

    const timeslots = (await FirmService.getTimeslots(date, state.cart.order.delivery_method as string, productIds, categoryIds)).data || []
    const responses = await Promise.all(timeslots.map(async timeslot => FirmService.getTimeIntervals(timeslot.id, date, state.cart.order.delivery_method as string, productIds, categoryIds)))

    const output = responses
      .map(response => response.intervals
        .filter(interval => interval.available && !interval.blocked)
        .filter(interval => interval.date === date.toISOString().substring(0, 10))
        .map(interval => ({
          interval,
          timeslot: response.timeslots.filter(timeslot => timeslot.from_time <= interval.time && timeslot.to_time > interval.time)[0]
        }))
        .filter(({ timeslot }) => timeslot)
      )
      .reduce((all, items) => all.concat(items), [])

    // deduplicate items
    return Object.values(Object.fromEntries(output.map((item => [ `${item.interval.date}${item.interval.time}`, item ]))))
  }
)
