import './EoImg.css'

interface Props {
  alt?: string,
  src?: string,
}

const EoImg: React.FC<Props> = ({ alt, src }) => {
  return (
    <div className={src ? 'eo-img' : 'eo-img no-src'}>
      <img alt={alt} src={src} />
    </div>
  )
}

export default EoImg
