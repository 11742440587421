"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config_1 = require("../../config");
var ApiConfiguration = /** @class */ (function () {
    function ApiConfiguration() {
    }
    ApiConfiguration.setBaseUrl = function (url) {
        this._baseUrl = url;
    };
    ApiConfiguration.getBaseUrl = function () {
        return this._baseUrl;
    };
    ApiConfiguration.setHeaders = function (headers) {
        this._headers = headers;
    };
    ApiConfiguration.addOrReplaceHeader = function (key, value) {
        this._headers[key] = value;
    };
    ApiConfiguration.deleteHeader = function (key) {
        delete this._headers[key];
    };
    ApiConfiguration.getAllHeaders = function () {
        return this._headers;
    };
    ApiConfiguration.getHeader = function (name) {
        return this._headers[name];
    };
    ApiConfiguration._baseUrl = config_1.Config.BASE_URL;
    ApiConfiguration._headers = {};
    return ApiConfiguration;
}());
exports.default = ApiConfiguration;
