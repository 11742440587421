import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  useIonAlert
} from '@ionic/react'
import { FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { lockClosedOutline, personOutline } from 'ionicons/icons'
import './Login.css'
import { loginAsManager } from '../stores/user/thunks'
import { useAppDispatch } from '../stores'
import { loadProducts } from '../stores/products/thunks'
import { loadFirm } from '../stores/firm/thunks'
import { resetDeliveryMethod } from '../stores/cart/thunks'
import { ApiConfiguration } from '@eo-storefronts/eo-core'
import { Preferences } from '@capacitor/preferences'
import { useTranslation } from 'react-i18next'

let loginResult

const Login: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [ presentAlert ] = useIonAlert()
  const [ loading, setLoading ] = useState(false)
  const { t } = useTranslation()

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()
    login((event.target as any).username.value, (event.target as any).password.value)
  }

  const login = async (username: string, password: string) => {
    setLoading(true)
    ApiConfiguration.setBaseUrl('https://api.easyorderapp.com')
    const promise = dispatch(loginAsManager({ username, password }))
    await (loginResult = promise)
    if (loginResult !== promise) {
      return
    }
    await Preferences.set({ key: 'auth', value: JSON.stringify({ username, password }) })
    if (!(await dispatch(loadFirm())).payload) {
      presentAlert({
        header: t('alert.error'),
        message: t('login.invalidCredentials'),
        buttons: [ t('alert.dismiss') ]
      })
      setLoading(false)
      return
    }
    await dispatch(resetDeliveryMethod())
    setLoading(false)
    dispatch(loadProducts())
    history.push('/products')
  }

  useEffect(() => {
    Preferences.get({ key: 'auth' }).then(async ({ value }) => {
      if (value) {
        const auth = JSON.parse(value)

        await login(auth.username, auth.password)
      }
    })
  }, [])

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="login-container">
          <form className="login-form" onSubmit={onSubmit}>
            <h1 style={{ marginBottom: '1em' }}>{t('login.title')}</h1>
            <IonItem lines="none" style={{ marginBottom: '1em' }}>
              <IonIcon slot="start" icon={personOutline} />
              <IonInput placeholder={t('login.username') as string} name="username" required disabled={loading} />
            </IonItem>
            <IonItem lines="none" style={{ marginBottom: '1em' }}>
              <IonIcon slot="start" icon={lockClosedOutline} />
              <IonInput type="password" placeholder={t('login.password') as string} name="password" required disabled={loading} />
            </IonItem>
            <IonButton expand="block" mode="md" type="submit" disabled={loading}>{t('login.submit')}</IonButton>
          </form>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Login
