import { addToCart, amendCart } from '../stores/cart/slices'
import { useAppDispatch } from '../stores'
import { Product } from '@eo-storefronts/eo-core'
import { useIonToast } from '@ionic/react'
import { useLanguage } from './useLanguage'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectCalculatePrice, selectCartItems } from '../stores/cart/selectors'

export const useAddToCart = () => {
  const dispatch = useAppDispatch()
  const calculatePrice = useSelector(selectCalculatePrice)
  const language = useLanguage()
  const [ present ] = useIonToast()
  const { t } = useTranslation()

  return async (product: Product, modifiers: Record<string, number>, comment?: string) => {
    const totalPrice = calculatePrice(product.id.toString(), modifiers)
    await dispatch(addToCart({ product, modifiers, totalPrice, comment }))
    document.dispatchEvent(new CustomEvent('productAdded', { detail: product }))
    present({ message: t('cart.addedToCart', { product: product.name[language] }), position: 'top', duration: 1500 })
  }
}

export default useAddToCart

export const useAmendCart = () => {
  const dispatch = useAppDispatch()
  const calculatePrice = useSelector(selectCalculatePrice)
  const cartItems = useSelector(selectCartItems)

  return async (index: number, modifiers: Record<string, number>, comment?: string) => {
    const totalPrice = calculatePrice(cartItems[index].productId.toString(), modifiers)
    await dispatch(amendCart({ index, modifiers, totalPrice, comment }))
  }
}
