import React, { ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { pencil } from 'ionicons/icons'
import Stepper from './Stepper'
import { useLanguage } from '../hooks/useLanguage'
import { Modifier, Product } from '@eo-storefronts/eo-core'
import { ProductThumbnail } from './ProductThumbnail'
import { ProductModifiers } from './ProductModifiers'

type Props = {
  index: number,
  product: Product,
  modifiers: {
    modifier: Modifier,
    quantity: number,
  }[],
  quantity: number,
  showEdit: boolean,
  totalPrice: number,
  onUpdateCount(count: number): void,
  onUpdatePrice(price: number): void,
};

export const CartItem: React.FC<Props> = ({ index, product, modifiers, quantity, showEdit, totalPrice, onUpdateCount, onUpdatePrice }) => {
  const language = useLanguage()
  const priceInput = useRef<HTMLInputElement>(null)
  const [ priceValue, setPriceValue ] = useState<string>('')

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    const price = parseFloat(event.target.value as string)
    event.target.value = price.toFixed(2)
    onUpdatePrice(price)
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setPriceValue(event.target.value)

  const onKeyDown = (event: KeyboardEvent) => {
    const input = priceInput.current as HTMLInputElement

    if (event.key === 'Escape') {
      input.value = totalPrice.toFixed(2)
      input.blur()
    } else if (event.key === 'Enter') {
      input.blur()
    }
  }

  useEffect(() => setPriceValue(totalPrice.toFixed(2)), [ totalPrice ])

  return (
    <IonItem lines="none">
      <ProductThumbnail product={product} slot="start" />
      <IonLabel className="ion-text-wrap">
        <div><strong>{product.name[language]}</strong></div>
        <ProductModifiers modifiers={modifiers} />
        <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            <input ref={priceInput} type="text" inputMode="decimal" value={priceValue} onChange={onChange} onKeyDown={onKeyDown} onBlur={onBlur} style={{ width: '100%', maxWidth: '75px' }} />
          </div>
          {showEdit && product.modifierGroups.length > 0 && (
            <IonButton mode="md" routerLink={`/edit/${index}`}>
              <IonIcon slot="icon-only" style={{ fontSize: '16px' }} icon={pencil}/>
            </IonButton>
          )}
          <Stepper value={quantity} onChange={onUpdateCount} />
        </div>
      </IonLabel>
    </IonItem>
  )
}
