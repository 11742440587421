import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import './Products.css'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  cartOutline,
  closeOutline,
  locationOutline,
  personCircleOutline,
  restaurantOutline
} from 'ionicons/icons'
import AddToCartAnimation from '../components/AddToCartAnimation'
import SearchCustomerModal from '../components/SearchCustomerModal'
import CreateCustomerModal from '../components/CreateCustomerModal'
import SelectAddressModal from '../components/SelectAddressModal'
import SelectTableModal from '../components/SelectTableModal'
import Cart from '../components/Cart'
import CheckoutModal from '../components/CheckoutModal'
import ChangeDeliveryModal from '../components/ChangeDeliveryModal'
import RemarksModal from '../components/RemarksModal'
import DiscountModal from '../components/DiscountModal'
import TimeDateModal from '../components/TimeDateModal'
import EoImg from '../components/EoImg'
import { useAppDispatch } from '../stores'
import { useLanguage } from '../hooks/useLanguage'
import { selectCategories, selectProducts, selectProductsLoading } from '../stores/products/selectors'
import { Address, Category, Customer, DeliveryMethodNames } from '@eo-storefronts/eo-core'
import {
  selectCart,
  selectCustomer,
  selectOrder,
  selectOrderAddress
} from '../stores/cart/selectors'
import {
  setCustomer,
  setDeliveryAddress,
  setDeliveryMethod,
  setTableNumber
} from '../stores/cart/slices'
import { selectCurrency, selectDeliveryMethods, selectDisplayImages, selectFirm } from '../stores/firm/selectors'
import { getOrderCount } from '../stores/customers/thunks'
import { selectCustomerOrderCount } from '../stores/customers/selectors'
import useAddToCart from '../hooks/useAddToCart'
import { useTranslation } from 'react-i18next'

const Products: React.FC = () => {
  const dispatch = useAppDispatch()
  const addToCart = useAddToCart()
  const language = useLanguage()
  const currency = useSelector(selectCurrency)
  const categories2 = useSelector(selectCategories)
  const products2 = useSelector(selectProducts)
  const productsLoading = useSelector(selectProductsLoading)
  const { cart, cartDiscount, cartTotal, cartTotalDiscounted } = useSelector(selectCart)
  const customer = useSelector(selectCustomer)
  const [ order ] = useSelector(selectOrder)
  const orderAddress = useSelector(selectOrderAddress)
  const firm = useSelector(selectFirm)
  const deliveryMethods = useSelector(selectDeliveryMethods)
  const displayImages = useSelector(selectDisplayImages)
  const orderCount = useSelector(selectCustomerOrderCount)
  const { t } = useTranslation()

  const [ state, setState ] = useState({
    category: 0,
    subCategory: 0,
    discount: null as string|null,
    search: '',
    modal: null as string|null,
    query: undefined as string|undefined
  })

  const createCustomer = (query: string) => {
    setState({ ...state, modal: 'create', query })
  }

  const customerSelected = (customer: Customer) => {
    dispatch(setCustomer(customer))
    dispatch(getOrderCount(customer.id))
    setState({ ...state, modal: null })

    if (customer.country) {
      dispatch(setDeliveryAddress({
        country: customer.country,
        country_id: customer.country.id,
        description: '',
        house_number: customer.house_number,
        id: 0,
        is_default: true,
        locality: customer.locality,
        street: customer.street,
        zip_code: customer.zipcode
      }))
    }
  }

  const addressSelected = (address: Address) => {
    dispatch(setDeliveryAddress(address))
    setState({ ...state, modal: null })
  }

  const parentCategories = Object.values(categories2).filter(({ parent_id }) => !parent_id).sort((a: Category, b: Category) => a.sort - b.sort)
  const subCategories = parentCategories[state.category]?.subcategories.map(categoryId => categories2[categoryId]) || []
  const products = state.search
    ? Object.values(products2).filter(product => product.name[language]?.toLowerCase().includes(state.search.toLowerCase()))
    : (subCategories.length ? subCategories[state.subCategory] : parentCategories[state.category])?.products.map(id => products2[id]) || []

  return (
    <IonPage>
      <AddToCartAnimation />

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Products</IonTitle>
        </IonToolbar>
      </IonHeader>

      {firm && <IonContent style={{ '--padding-end': '350px' }}>
        <IonHeader className="products-header" collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{t('products.title')}</IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar placeholder={t('products.searchPlaceholder') as string} onIonChange={(ev) => setState({ ...state, search: String(ev.target.value) })} />
          </IonToolbar>
        </IonHeader>
        {!state.search && <div className="ion-padding">
          {parentCategories.map((category, index) => <IonButton key={category.id} color={index === state.category ? 'primary' : 'white'} onClick={() => setState({ ...state, category: index })}>{category.name[language]}</IonButton>)}
        </div>}
        {!state.search && subCategories.length > 0 && <div className="ion-padding">
          {subCategories.map((category, index) => <IonButton key={category.id} color={index === state.subCategory ? 'primary' : 'white'} onClick={() => setState({ ...state, subCategory: index })}>{category.name[language]}</IonButton>)}
        </div>}
        {productsLoading && <div className="ion-padding">
          <IonSpinner></IonSpinner> {t('products.productsLoading')}
        </div>}
        {!productsLoading && !products.length && <p className="ion-padding">{t('products.noProductsFound')}</p>}
        <IonGrid>
          <IonRow>
            {products.map(product => <IonCol size="3" key={product.id}>
              <IonCard className="ion-activatable product-card" disabled={!product.inStock} onClick={async () => !product.modifierGroups.length && addToCart(product, {})} routerLink={product.modifierGroups.length ? `/product/${product.id}` : undefined}>
                <IonCardContent className={`animate-product-${product.id}`}>
                  {displayImages.product && <EoImg alt={product.name[language] as string} src={product.images[0]?.thumb} />}
                  <IonCardTitle>{product.name[language]}</IonCardTitle>
                  <div className="product-card-price">
                    <span>{currency?.symbol} {product.price.toFixed(2)}</span>{product.unit && <> / {product.unit.name_singular}</>}
                  </div>
                </IonCardContent>

                <IonRippleEffect />
              </IonCard>
            </IonCol>)}
          </IonRow>
        </IonGrid>

        <div slot="fixed" className="eo-cart-sidebar ion-padding" style={{ width: '350px' }}>
          <div className={`current-order-${order.delivery_method}`}>
            <div style={{ fontSize: '24px', fontWeight: 700 }}>{t('cart.currentOrder')}</div>
            <IonCard color="light">
              <IonSegment value={order.delivery_method} onIonChange={event => dispatch(setDeliveryMethod(event.detail.value as DeliveryMethodNames))}>
                {deliveryMethods.map(deliveryMethod => <IonSegmentButton key={deliveryMethod.key} value={deliveryMethod.key} disabled={deliveryMethod.disabled}>
                  <IonLabel>{t(`deliveryMethods.${deliveryMethod.key}`)}</IonLabel>
                </IonSegmentButton>)}
              </IonSegment>
              <IonItem lines="none" onClick={() => setState({ ...state, modal: 'search' })}>
                <IonIcon slot="start" icon={personCircleOutline} />
                <div className="order-container customer-container">
                  {customer ? <div>
                    <div>{customer.first_name} {customer.last_name}</div>
                    <div>{customer.phone ?? 'no phone'} &mdash; {customer.email ?? 'no email'}</div>
                    <div>{orderCount === null && <IonSpinner name="dots" style={{ height: '16px', verticalAlign: 'bottom' }} />} {t('checkout.pastOrders', { count: orderCount ?? undefined })}</div>
                  </div> : t('checkout.selectCustomer')}
                </div>
              </IonItem>
              {order.delivery_method === DeliveryMethodNames.DELIVERY && <IonItem lines="none" onClick={() => setState({ ...state, modal: 'address' })}>
                <IonIcon slot="start" icon={locationOutline}/>
                <div className="order-container">{orderAddress || t('checkout.selectAddress')}</div>
              </IonItem>}
              {order.delivery_method === DeliveryMethodNames.ON_THE_SPOT && <IonItem lines="none">
                <IonIcon slot="start" icon={restaurantOutline}/>
                <div className="order-container" onClick={() => setState({ ...state, modal: 'table' })}>{order.table_number || t('checkout.selectTable')}</div>
                {order.table_number && <IonIcon onClick={() => dispatch(setTableNumber(null))} slot="end" icon={closeOutline}/>}
              </IonItem>}
            </IonCard>
          </div>
          <div className="cart-body">
            {cart.length ? <Cart showEdit={true} /> : <div className="cart-empty">
              <IonIcon color="medium" icon={cartOutline} />
              <div><IonText color="medium" style={{ whiteSpace: 'pre-wrap' }}>{t('cart.noProducts')}</IonText></div>
            </div>}
          </div>
          <div className="cart-footer">
            <div className="total-item total-item-sub">
              <div>{t('cart.subtotal')}</div>
              <div>{currency?.symbol} {cartTotal ? cartTotal.toFixed(2) : '—'}</div>
            </div>
            <div className="total-item total-item-sub">
              <div>{t('cart.discounts')}</div>
              <div>{currency?.symbol} {cartDiscount ? cartDiscount.toFixed(2) : '—'}</div>
            </div>
            <div className="total-item total-item-sum">
              <div>{t('cart.total')}</div>
              <div>{currency?.symbol} {cartTotal ? cartTotalDiscounted.toFixed(2) : '—'}</div>
            </div>
            <IonButton expand="block" mode="md" disabled={!cartTotal} onClick={() => setState({ ...state, modal: 'checkout' })}>{t('cart.continueToCheckout')}</IonButton>
          </div>

          <SearchCustomerModal isOpen={state.modal === 'search'} onClose={() => state.modal === 'search' && setState({ ...state, modal: null })} onCreate={createCustomer} onSelect={customerSelected} />
          <CreateCustomerModal isOpen={state.modal === 'create'} onClose={() => state.modal === 'create' && setState({ ...state, modal: null })} onCreate={customerSelected} query={state.query} />
          <SelectAddressModal isOpen={state.modal === 'address'} onClose={() => state.modal === 'address' && setState({ ...state, modal: null })} onSelect={addressSelected} />
          <SelectTableModal isOpen={state.modal === 'table'} onClose={() => state.modal === 'table' && setState({ ...state, modal: null })} onSelect={table => dispatch(setTableNumber(table)) && setState({ ...state, modal: null })} value={order.table_number} />
          <CheckoutModal isOpen={state.modal === 'checkout'} onClose={() => state.modal === 'checkout' && setState({ ...state, modal: null })} onClick={modal => setState({ ...state, modal })} />
          <ChangeDeliveryModal isOpen={state.modal === 'change-delivery'} onClose={() => state.modal === 'change-delivery' && setState({ ...state, modal: 'checkout' })} />
          <TimeDateModal isOpen={state.modal === 'time-date'} onClose={() => state.modal === 'time-date' && setState({ ...state, modal: 'checkout' })} />
          <RemarksModal isOpen={state.modal === 'remarks'} onClose={() => state.modal === 'remarks' && setState({ ...state, modal: 'checkout' })} />
          <DiscountModal isOpen={state.modal === 'discount'} onClose={() => state.modal === 'discount' && setState({ ...state, modal: 'checkout' })} />
        </div>
      </IonContent>}
    </IonPage>
  )
}

export default Products
