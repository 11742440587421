import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../index'
import {
  ApiConfiguration,
  CreateOrderIngredients,
  CreateOrderPayload,
  CreateOrderProduct,
  CreateOrderProductOptions,
  Customer,
  DeliveryMethodNames,
  FirmService,
  ManagerService,
  Modifier,
  UuidService
} from '@eo-storefronts/eo-core'
import { getQuantity, setDeliveryMethod } from './slices'
import { selectCalculatePrice } from './selectors'

// TODO document or rewrite
const getAction = (items: Record<string, number>, modifier: Modifier) => ({ '1': 'add', '-1': 'rem' })[Math.sign(items[modifier.id] || (modifier.isDefault ? 1 : 0)).toString()] || null

const withCustomer = async <Type>(customer: Customer|null, callback: () => Type): Promise<Type> => {
  const customerId = customer?.id ?? 1

  try {
    ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())
    return await callback()
  } finally {
    ApiConfiguration.deleteHeader('customerId')
  }
}

export const createOrder = createAsyncThunk.withTypes<{state: RootState}>()(
  'cart/createOrder',
  async (a, { getState }) => {
    const state = getState()

    const orderPayload: Partial<CreateOrderPayload> = { ...state.cart.order }

    orderPayload.products = state.cart.items.map((item): CreateOrderProduct => {
      const orderProduct = state.products.data?.products[item.productId]

      if (!orderProduct) {
        throw new Error('Product not found')
      }

      // TODO duplicate of apps/next-app/services/GenerateOrderPayloadService.ts:325
      const ingredients: Array<CreateOrderIngredients> = []
      const product_options: Array<CreateOrderProductOptions> = []

      for (const cartModifierGroup of orderProduct.modifierGroups.map(id => state.products.data?.modifierGroups[id])) {
        if (!cartModifierGroup) {
          throw new Error('Modifier group not found')
        }

        for (const modifier of cartModifierGroup.modifiers.map(id => state.products.data?.modifiers[id])) {
          if (!modifier) {
            throw new Error('Modifier not found')
          }

          const quantity = getQuantity(item.modifiers, modifier)

          if (modifier.type === 'ingredient') {
            const action = getAction(item.modifiers, modifier)
            if (!action) {
              continue
            }
            // if (action !== 'rem' && !quantity) {
            //   console.log('skipping ingredient', modifier, quantity)
            //   continue;
            // }
            ingredients.push({
              id: Number(modifier.id.split('-').pop()),
              type: modifier.ingredientType,
              price: modifier.price,
              quantity: action === 'rem' ? 1 : quantity,
              action: action,
              selected: modifier.isDefault || false
            })
          } else {
            if (!quantity) {
              continue
            }
            product_options.push({
              id: Number(modifier.id.split('-').pop()),
              group_id: Number(cartModifierGroup.id.split('-')[1]),
              price: modifier.price
            })
          }
        }
      }

      return ({
        id: orderProduct.id,
        price: item.totalPrice - (selectCalculatePrice(state)(orderProduct.id as string, item.modifiers) - orderProduct.price),
        parent_cat_id: orderProduct.categoryId,
        cat_id: orderProduct.categoryId || '',
        quantity: item.quantity,
        unit_id: orderProduct.unit?.id || '',
        weight: orderProduct.weight,
        ingredients,
        product_options,
        comment: item.comment
      })
    })

    if (orderPayload.coupon_product_id) {
      const freeProduct = orderPayload.products.find(product => product.id === orderPayload.coupon_product_id)
      if (freeProduct && freeProduct.quantity > 1) {
        freeProduct.quantity -= 1
      } else {
        orderPayload.products = orderPayload.products.filter(product => product !== freeProduct)
      }
    }

    if (orderPayload.delivery_method === DeliveryMethodNames.PICKUP || orderPayload.delivery_method === DeliveryMethodNames.DELIVERY) {
      if (state.firm.firm?.delivery_methods[orderPayload.delivery_method]?.order_timeslots.active) {
        if (!orderPayload.timeslot_id) {
          const timeslots = await FirmService.getTimeslots(new Date(), orderPayload.delivery_method, orderPayload.products.map(product => product.id), orderPayload.products.map(product => product.cat_id.toString()))

          if (!timeslots.data) {
            throw new Error('Invalid timeslots data')
          }

          orderPayload.timeslot_id = timeslots.data[0].id
        }

        if (state.cart.deliveryDate) {
          orderPayload.process_timestamp = new Date(state.cart.deliveryDate)
        } else {
          const intervals = await FirmService.getTimeIntervals(orderPayload.timeslot_id, new Date(), orderPayload.delivery_method, orderPayload.products.map(product => product.id), orderPayload.products.map(product => product.cat_id.toString()))
          const availableIntervals = intervals.intervals.filter(interval => interval.available)
          // orderPayload.process_timestamp = new Date('2023-01-29T17:30:00.000Z');
          orderPayload.process_timestamp = new Date(`${availableIntervals[0].date}T${availableIntervals[0].time}`)
        }
      } else {
        if (state.cart.deliveryDate) {
          orderPayload.process_timestamp = new Date(state.cart.deliveryDate)
        } else {
          orderPayload.process_timestamp = new Date()
        }
      }
    }

    orderPayload.checkout_options = orderPayload.checkout_options?.filter(option => option.selected)

    ApiConfiguration.addOrReplaceHeader('uuid', UuidService.generate())

    return withCustomer(state.cart.customer, async () => ManagerService.createOrder(orderPayload as CreateOrderPayload))
  }
)

export const resetDeliveryMethod = () => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const { firm, cart } = getState()

    if (firm.deliveryMethods.includes(cart.order.delivery_method as DeliveryMethodNames)) {
      return
    }

    for (const method of [ DeliveryMethodNames.PICKUP, DeliveryMethodNames.DELIVERY, DeliveryMethodNames.ON_THE_SPOT ]) {
      if (firm.deliveryMethods.includes(method)) {
        dispatch(setDeliveryMethod(method))
        return
      }
    }
  }
}
