"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentType = void 0;
var PaymentType;
(function (PaymentType) {
    PaymentType[PaymentType["custom"] = -1] = "custom";
    PaymentType[PaymentType["ogone"] = 2] = "ogone";
    PaymentType[PaymentType["ideal"] = 3] = "ideal";
    PaymentType[PaymentType["ems"] = 5] = "ems";
    PaymentType[PaymentType["stripe"] = 6] = "stripe";
    PaymentType[PaymentType["paynovate"] = 9] = "paynovate";
    PaymentType[PaymentType["mollie"] = 10] = "mollie";
    PaymentType[PaymentType["europabank"] = 11] = "europabank";
    PaymentType[PaymentType["adyen"] = 12] = "adyen";
})(PaymentType = exports.PaymentType || (exports.PaymentType = {}));
