import { createSlice } from '@reduxjs/toolkit'
import { ApiConfiguration, ClientAuthResponse, GuestAuthResponse } from '@eo-storefronts/eo-core'
import { loginAsGuest, loginAsManager, refreshToken } from './thunks'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    clientToken: null as ClientAuthResponse|null,
    guestToken: null as GuestAuthResponse|null,
    refreshFailures: 0
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsGuest.fulfilled, (state, action) => {
      state.clientToken = action.payload.clientToken
      state.guestToken = action.payload.guestToken
      ApiConfiguration.addOrReplaceHeader('customerToken', action.payload.guestToken.customerToken)
      ApiConfiguration.addOrReplaceHeader('Authorization', `${action.payload.clientToken.token_type} ${action.payload.clientToken.access_token}`)
    })
    builder.addCase(loginAsManager.fulfilled, (state, action) => {
      ApiConfiguration.addOrReplaceHeader('Authorization', `${action.payload.token_type} ${action.payload.access_token}`)
    })
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      ApiConfiguration.addOrReplaceHeader('Authorization', `${action.payload.token_type} ${action.payload.access_token}`)
      state.refreshFailures = 0
    })
    builder.addCase(refreshToken.rejected, (state) => {
      state.refreshFailures += 1
      if (state.refreshFailures > 2) {
        window.location.href = '/login'
      }
    })
  }
})

export default userSlice.reducer
