import { RootState } from '../index'
import {
  CreateOrderPayload,
  CustomPaymentMethod,
  DeliveryMethodNames,
  Modifier, ModifierGroup,
  PaymentMethodMethodEnum,
  Product
} from '@eo-storefronts/eo-core'
import { getQuantity } from './slices'
import { selectFirm } from '../firm/selectors'

export const selectCalculatePrice = (state: RootState) => {
  return (productId: string, modifiers: Record<string, number>) => Object.entries(modifiers)
    .reduce((sum, [ id, quantity ]) => sum + (state.products.data?.modifiers[id].price ?? 0) * quantity, (state.products.data?.products)?.[productId].price ?? 0)
}

export const selectCart = (state: RootState) => {
  const cart = state.cart.items.map(item => {
    const product = state.products.data?.products?.[item.productId] as Product
    const modifierGroups = product.modifierGroups
      .map(id => state.products.data?.modifierGroups[id])
      .filter((modifierGroup): modifierGroup is ModifierGroup => modifierGroup !== undefined)
    const modifiers = modifierGroups.reduce((all, group) => {
      const subModifiers = group.modifiers
        .map(id => state.products.data?.modifiers[id])
        .filter((modifier): modifier is Modifier => modifier !== undefined)
        .map(modifier => ({ modifier, quantity: getQuantity(item.modifiers, modifier) }))

      if (!group.isMultipleChoice) {
        // only display selected item
        return all.concat(subModifiers.filter(({ quantity }) => quantity === 1))
      }

      // only display items which differ from defaults
      return all.concat(subModifiers.filter(({ modifier }) => item.modifiers[modifier.id]))
    }, [] as {modifier: Modifier, quantity: number}[])

    return ({
      product,
      modifiers,
      quantity: item.quantity,
      totalPrice: item.totalPrice
    })
  })

  const cartOptions = state.cart.order.checkout_options?.filter(item => item.selected).reduce((sum, item) => sum + item.price, 0) ?? 0
  const cartTotal = cart.reduce((sum, item) => sum + item.totalPrice * item.quantity, 0) + cartOptions
  const cartDiscount = Math.min(state.cart.coupon?.value ?? 0, cartTotal)
  const cartTotalDiscounted = cartTotal - cartDiscount

  return { cart, cartDiscount, cartTotal, cartTotalDiscounted }
}

export const selectCartErrors = (state: RootState) => {
  if (!state.cart.items.length) {
    return [ 'Cart is empty' ]
  }

  if (state.cart.order.delivery_method === DeliveryMethodNames.ON_THE_SPOT && state.firm.firm?.delivery_methods[DeliveryMethodNames.ON_THE_SPOT]?.number_required && !state.cart.order.table_number) {
    return [ 'No table number was selected' ]
  }

  if (state.cart.order.delivery_method === DeliveryMethodNames.DELIVERY && !state.cart.order.delivery_street) {
    return [ 'No delivery address was selected' ]
  }

  if (state.cart.order.delivery_method === DeliveryMethodNames.DELIVERY && state.firm.firm?.min_price_for_delivery && selectCart(state).cartTotal < parseFloat(state.firm.firm.min_price_for_delivery)) {
    return [ `Order total is lower than minimum delivery price of ${state.firm.firm.currency.symbol} ${state.firm.firm.min_price_for_delivery}` ]
  }

  return []
}

export const selectCartItems = (state: RootState) => state.cart.items

export const selectCustomer = (state: RootState) => state.cart.customer

export const selectOrder = (state: RootState): [Omit<Partial<CreateOrderPayload>, 'products'>, Date|null, boolean] => [
  state.cart.order,
  state.cart.deliveryDate ? new Date(state.cart.deliveryDate) : null,
  state.cart.createOrderLoading
]

export const selectOrderAddress = (state: RootState) => state.cart.order.delivery_street
  ? `${state.cart.order.delivery_street} ${state.cart.order.delivery_street_nr} — ${state.cart.order.delivery_zipcode} ${state.cart.order.delivery_locality}`
  : null

export const selectPaymentMethodId = (state: RootState) => {
  return state.cart.order.firm_custom_delivery_pickup_payment_method_id ?? state.cart.order.payment_method_id ?? null
}

export const selectPaymentMethods = (state: RootState) => {
  const firm = state.firm.firm

  // TODO duplicate of apps/next-app/hooks/checkout/usePaymentMethods.ts
  if (firm && firm.payment_methods.length > 0) {
    let paymentMethods = firm.payment_methods.filter(method => method.method as string === state.cart.order.delivery_method)

    if (state.cart.order.delivery_method === DeliveryMethodNames.DELIVERY && firm.custom_delivery_payment_methods) {
      paymentMethods = [
        ...paymentMethods,
        ...firm.custom_delivery_payment_methods.map((customPaymentMethod: CustomPaymentMethod) => ({
          payment_method_id: customPaymentMethod.id,
          payment_method: customPaymentMethod.name,
          method: PaymentMethodMethodEnum.CUSTOM
        }))
      ]
    }

    if (state.cart.order.delivery_method === DeliveryMethodNames.PICKUP && firm.custom_pickup_payment_methods) {
      paymentMethods = [
        ...paymentMethods,
        ...firm.custom_pickup_payment_methods.map((customPaymentMethod: CustomPaymentMethod) => ({
          payment_method_id: customPaymentMethod.id,
          payment_method: customPaymentMethod.name,
          method: PaymentMethodMethodEnum.CUSTOM
        }))
      ]
    }

    return paymentMethods
  }

  return []
}

export const selectUseTimeslots = (state: RootState) => (state.cart.order.delivery_method === DeliveryMethodNames.PICKUP || state.cart.order.delivery_method === DeliveryMethodNames.DELIVERY)
  && selectFirm(state)?.delivery_methods[state.cart.order.delivery_method]?.order_timeslots.active || false

export const selectProductIds = (state: RootState) => state.cart.items.map(item => item.productId)
export const selectCategoryIds = (state: RootState) => state.cart.items
  .map(item => state.products.data?.products[item.productId]?.categoryId ?? null)
  .filter((id): id is string => id !== null)
