import { Loader } from '@googlemaps/js-api-loader'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectFirm } from '../stores/firm/selectors'

const promise = new Loader({
  apiKey: 'AIzaSyCA6sNMb5P6cOc5JWzKMoiVkMQhYonqf6g',
  libraries: [ 'places' ]
}).load()

export default () => {
  const streetInput = useRef<HTMLIonInputElement>(null)
  const firm = useSelector(selectFirm)

  return {
    ref: streetInput,
    setup: async (callback: (input: HTMLInputElement, components: {[k: string]: google.maps.GeocoderAddressComponent}) => void) => {
      await promise
      const input = (streetInput.current as HTMLIonInputElement).querySelector('input') as HTMLInputElement
      const autocomplete = new google.maps.places.Autocomplete(input, {
        componentRestrictions: {
          country: ((firm?.countries?.length ?? 0) > 5 ? null : firm?.countries)?.map(country => country.iso) ?? null
        }
      })
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()

        if (!place.address_components) {
          return
        }

        callback(input, Object.fromEntries(
          place.address_components.reduce(
            (all, component) => all.concat(component.types.map(type => [ type, component ])),
            [] as [string, google.maps.GeocoderAddressComponent][]
          )
        ))
      })
    }
  }
}
