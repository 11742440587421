import { RootState } from '../index'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'

export const selectFirm = (state: RootState) => state.firm.firm

export const selectDeliveryMethods = (state: RootState) => {
  return [
    DeliveryMethodNames.PICKUP,
    DeliveryMethodNames.DELIVERY,
    DeliveryMethodNames.ON_THE_SPOT
  ]
    .map(key => ({
      key,
      disabled: !state.firm.deliveryMethods.includes(key)
    }))
}

export const selectDisplayImages = (state: RootState) => state.firm.displayImages

export const selectCountries = (state: RootState) => state.firm.firm?.countries || []

export const selectCurrency = (state: RootState) => state.firm.firm?.currency
