import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import user from './user/slices'
import products from './products/slices'
import cart from './cart/slices'
import firm from './firm/slices'
import terminals from './terminals/slices'
import timeslots from './timeslots/slices'
import { addresses, customers } from './customers/slices'

const reducer = combineReducers({
  addresses,
  cart,
  customers,
  firm,
  products,
  terminals,
  timeslots,
  user
})

const store = configureStore({ reducer })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types

export default store
