"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
var Config = /** @class */ (function () {
    function Config() {
    }
    Object.defineProperty(Config, "BASE_URL", {
        /* ---- Base endpoints url ---- */
        get: function () { return 'https://api-dev.easyorderapp.com'; },
        enumerable: false,
        configurable: true
    });
    return Config;
}());
exports.Config = Config;
