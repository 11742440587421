import { createSlice } from '@reduxjs/toolkit'
import { Interval, Timeslot } from '@eo-storefronts/eo-core'
import { loadTimeslots } from './thunks'

export const timeslotsSlice = createSlice({
  name: 'timeslots',
  initialState: {
    timeslots: [] as {interval: Interval, timeslot: Timeslot}[],
    timeslotsLoading: false
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(loadTimeslots.pending, (state) => {
      state.timeslots = []
      state.timeslotsLoading = true
    })
    builder.addCase(loadTimeslots.fulfilled, (state, action) => {
      state.timeslots = action.payload
      state.timeslotsLoading = false
    })
  }
})

export default timeslotsSlice.reducer
