import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Address,
  CheckoutOption,
  Coupon,
  CreateOrderPayload,
  CreateOrderProduct,
  Customer,
  DeliveryMethodNames,
  Id,
  Modifier,
  PaymentMethod,
  PaymentMethodMethodEnum,
  Product
} from '@eo-storefronts/eo-core'
import { createOrder } from './thunks'

interface CartItem extends Pick<CreateOrderProduct, 'comment' | 'quantity'> {
  id: string,
  productId: number,
  modifiers: Record<string, number>,
  totalPrice: number,
}

export const getQuantity = (items: Record<string, number>, modifier: Modifier) => (modifier.isDefault ? 1 : 0) + (items[modifier.id] || 0)

const createEmpty = (): Partial<CreateOrderPayload> => ({
  add_loyalty_to_order: false,
  asap: true,
  delivery_method: DeliveryMethodNames.PICKUP,
  order_sub_total: 0,
  order_total: 0,
  payment_method_id: 1,
  products: [],
  // properties that should be nullable and make Partial<> required
  process_timestamp: undefined,
  timeslot_id: undefined,
  user: undefined
})

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    order: createEmpty(),
    items: [] as CartItem[],
    coupon: null as Coupon|null,
    customer: null as Customer|null,
    deliveryDate: null as number|null,
    createOrderLoading: false
  },
  reducers: {
    addToCart: (state, action: PayloadAction<{product: Product, modifiers: Record<string, number>, totalPrice: number} & Pick<CreateOrderProduct, 'comment'>>) => {
      const { product, modifiers, totalPrice, comment } = action.payload

      let item = state.items.filter(item => !Object.entries(item.modifiers).length && !Object.entries(modifiers).length && item.comment === comment).find(item => item.productId === product.id)

      if (!item) {
        item = { id: Date.now().toString(), productId: product.id as number, quantity: 0, modifiers, totalPrice, comment }
        state.items.push(item)
      }

      item.quantity++

      // for now, just clear any coupons on cart change
      state.coupon = null
      state.order.coupon_code = undefined
      state.order.coupon_product_id = undefined

      return state
    },
    amendCart(state, action: PayloadAction<{index: number, modifiers: Record<string, number>, totalPrice: number} & Pick<CreateOrderProduct, 'comment'>>) {
      state.items[action.payload.index].comment = action.payload.comment
      state.items[action.payload.index].modifiers = action.payload.modifiers
      state.items[action.payload.index].totalPrice = action.payload.totalPrice

      return state
    },
    clearCart: (state) => {
      const previousDeliveryMethod = state.order.delivery_method

      state.coupon = null
      state.customer = null
      state.deliveryDate = null
      state.items = []
      state.order = createEmpty()
      state.order.delivery_method = previousDeliveryMethod

      return state
    },
    setCheckoutOption: (state, action: PayloadAction<CheckoutOption>) => {
      state.order.checkout_options = (state.order.checkout_options || []).filter(option => option.id !== action.payload.id)
      state.order.checkout_options.push(action.payload)

      return state
    },
    setComment: (state, action: PayloadAction<string|undefined>) => {
      state.order.comment = action.payload

      return state
    },
    setCoupon: (state, action: PayloadAction<Coupon & {code: string, productId?: Id}|null>) => {
      state.coupon = action.payload
      state.order.coupon_code = action.payload?.code
      state.order.coupon_product_id = action.payload?.productId

      return state
    },
    setCustomer: (state, action: PayloadAction<Customer|null>) => {
      state.customer = action.payload
      state.order.delivery_street = undefined
      state.order.delivery_street_nr = undefined
      state.order.delivery_zipcode = undefined
      state.order.delivery_locality = undefined
      state.order.delivery_country_id = undefined

      return state
    },
    setDeliveryAddress: (state, action: PayloadAction<Address>) => {
      state.order.delivery_street = action.payload.street
      state.order.delivery_street_nr = action.payload.house_number
      state.order.delivery_zipcode = action.payload.zip_code
      state.order.delivery_locality = action.payload.locality
      state.order.delivery_country_id = action.payload.country_id ?? action.payload.country?.id

      return state
    },
    setDeliveryDate: (state, action: PayloadAction<{timestamp: number|null, timeslot_id: Id|null}>) => {
      state.deliveryDate = action.payload.timestamp
      state.order.asap = !action.payload.timestamp
      state.order.timeslot_id = action.payload.timeslot_id ?? undefined

      return state
    },
    setDeliveryMethod: (state, action: PayloadAction<DeliveryMethodNames>) => {
      state.order.delivery_method = action.payload

      return state
    },
    setPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      if (action.payload.method === PaymentMethodMethodEnum.CUSTOM) {
        state.order.payment_method_id = 1
        state.order.firm_custom_delivery_pickup_payment_method_id = action.payload.payment_method_id
      } else {
        state.order.payment_method_id = action.payload.payment_method_id
        state.order.firm_custom_delivery_pickup_payment_method_id = undefined
      }

      return state
    },
    setTableNumber: (state, action: PayloadAction<string | null>) => {
      state.order.table_number = action.payload ?? undefined

      return state
    },
    updatePrice: (state, action: PayloadAction<{index: number, totalPrice: number}>) => {
      const { index, totalPrice } = action.payload

      state.items[index].totalPrice = totalPrice

      // for now, just clear any coupons on cart change
      state.coupon = null
      state.order.coupon_code = undefined
      state.order.coupon_product_id = undefined

      return state
    },
    updateQuantity: (state, action: PayloadAction<{index: number, quantity: number}>) => {
      const { index, quantity } = action.payload

      if (quantity) {
        state.items[index].quantity = quantity
      } else {
        state.items = state.items.filter((item, filterIndex) => filterIndex !== index)
      }

      // for now, just clear any coupons on cart change
      state.coupon = null
      state.order.coupon_code = undefined
      state.order.coupon_product_id = undefined

      return state
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createOrder.pending, (state) => {
      state.createOrderLoading = true
    })
    builder.addCase(createOrder.fulfilled, (state) => {
      state.createOrderLoading = false
    })
    builder.addCase(createOrder.rejected, (state) => {
      state.createOrderLoading = false
    })
  }
})

export const {
  addToCart,
  amendCart,
  clearCart,
  setCheckoutOption,
  setComment,
  setCoupon,
  setCustomer,
  setDeliveryAddress,
  setDeliveryDate,
  setDeliveryMethod,
  setPaymentMethod,
  setTableNumber,
  updatePrice,
  updateQuantity
} = cartSlice.actions

export default cartSlice.reducer
